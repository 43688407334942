<template>
    <div class='project-page'>
        <ProjectHero :src="'Mushroom/full_system.jpg'"
        :sides="60"  :min-radius="0.6" :theme-color="themeColor">
            <template v-slot:title>
                Mushroom Data Valve
            </template>
            <template v-slot:subtitle>
                A Mushroom Controlled Text Streaming Device
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">The Mushroom Data Valve is a prototype to control the loading and rendering speed of an API call to a random text generator on a webpage. Its ultimate behavior as a product is to control the internet bandwidth of a machine using a mushroom. By pressing the mushroom down with a human hand, a user can speed up the loading speed as if the mushroom was a valve on the data stream. This project features capacitive sensing, flex sensing, Circuit Python, Flask mini server, HTML and JavaScript. The purpose of the project is to expose the entanglement of data and living things, and speculate on the possibility of multispecies digital interface. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Independent Project</span>
                <span>Time: November - December 2021</span>
                <span>As the final project for 48727 <b>Inquiry into Computational Design</b> taught by Prof. Daniel Cardoso Llach at Carnegie Mellon University.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Inspiration</span>
                <span>The Mushroom Data Valve is a speculative prototype that explores various forms of entanglement among living things, food, information, text, and humans. With mushrooms being a kind of food for humans while being the decomposer in nature, I question how living things could influence our digital/cyber life in reducing or constructing meanings. </span>
                <span>The concept is inspired by Donna Haraway’s “sympoiesis”, making-with, in the world of human and non-human coexistence, and Anna Tsing’s book, “The Mushroom at the End of the World”, that shows how food in this damaged world could involve so many aspects of the society. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Materials</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <ul>
                    <li>White mushroom x1</li>
                    <li>Foam 10x6.5x1cm</li>
                    <li>Foam 10x6.5x2cm</li>
                    <li>Fabric to wrap around the foam</li>
                    <li>Nonconductive thread about 30cm</li>
                    <li>Conductive thread about 20cm</li>
                    <li>Metal snap button x1</li>
                    <li>Rubber bands</li>
                    <li>Wires</li>
                    <li>Metal clips x4</li>
                    <li>Tape</li>
                    <li>10k Ohm resistor x1</li>
                    <li>Adafruit Gemma board</li>
                    <li>SparkFun Flex Sensor 4.5 inches</li>
                    <li>Micro USB Cable</li>
                    <li>Soldering equipment</li>
                </ul>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Making Process</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
             <v-col md='12' sm='12'>
                <span class='section-subtitle'>Assembling the Mushroom, Foam, and Sensors</span>
             </v-col>
        </v-row>
        <v-row class="wrapper">
             <v-col md='6' sm='12'>
                <v-img
                    src="@/assets/Mushroom/1-foam-hole.jpg"
                ></v-img>
                <span class = "mt-5" >Cut a hole that is about 1.5cm in diameter in the center of the thinner piece of foam. </span>
                <span>Cut a piece of fabric enough to wrap around the thicker piece of foam. Sew together the long edge with nonconductive thread and make it into a sleeve. </span>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img
                    src="@/assets/Mushroom/2-button.jpg"
                ></v-img>
                <span class = "mt-5">Use the conductive thread, sew the socket of the snap button onto the center of the sleeve with the socket exposed and aligned to the hole cut in the thinner piece of foam. Then use the same thread and run stiches all the way to the end of the sleeve, wrapping around the edge a few times so the wire will have good contact with the thread. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
             <v-col md='5' sm='12'>
                <v-img
                    src="@/assets/Mushroom/3-flex-sensor.jpg"
                ></v-img>
                <span class = "mt-5">Stack the thinner piece of foam on top of the thicker one and secure them with two rubber bands. </span>
                <span>Then, solder the pins of the flex sensor onto two wires. </span>
            </v-col>
            <v-col md='7' sm='12'>
                <v-img
                    src="@/assets/Mushroom/mushroom-stacked-with-bg.jpg"
                ></v-img>
                <span class="mt-5">Put the thicker piece of foam into the sleeve. Insert the flex senor right under the snap button with the metal square side up and the hole and tape the head and tail of the flex sensor to the foam. </span>
                <span>Put the mushroom into the hole and the physical mushroom valve is done.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
             <v-col md='12' sm='12'>
                <span class='section-subtitle'>Wiring</span>
             </v-col>
             <v-col md='10' sm='12'>
                <v-img
                    src="@/assets/Mushroom/circuit.png"
                ></v-img>
            </v-col>
            <v-col md='12' sm='12'>
                <span>I made a voltage divider with the variable resistor – flex sensor – and the 10kOhm resistor. The flex sensor measures 20kOhm when it’s flat, and the value decreases when it’s bent into the foam. </span>
                <span>The conductive thread from the button goes to a wire, then to pin A0. </span>
                <span>All the wires are hooked to and taped onto the metal clips and clip onto Gemma. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Software and Code</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
             <v-col md='12' sm='12'>
                <span class='section-subtitle'>Circuit Python on Gemma</span>
             </v-col>
            <v-col md='12' sm='12'>
                <span>Circuit Python code on Gemma reads both the capacitive touch sensor and the flex sensor, plus it has a visual indicator light of whether I touch the mushroom or not. </span>
            </v-col>
            <v-col md='12' sm='12'>
                <!-- HTML generated using hilite.me -->
                <div class="highlighted-code" style="background: #ffffff; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%">touch_pad <span style="color: #333333">=</span> board<span style="color: #333333">.</span>A0
touch <span style="color: #333333">=</span> touchio<span style="color: #333333">.</span>TouchIn(touch_pad)

<span style="color: #008800; font-weight: bold">while</span> <span style="color: #007020">True</span>:
    ratio <span style="color: #333333">=</span> analog_pin<span style="color: #333333">.</span>value<span style="color: #333333">/</span><span style="color: #0000DD; font-weight: bold">65536</span>
    resistance <span style="color: #333333">=</span> <span style="color: #0000DD; font-weight: bold">5</span><span style="color: #333333">*</span>ratio<span style="color: #333333">/</span>(<span style="color: #0000DD; font-weight: bold">1</span><span style="color: #333333">-</span>ratio)<span style="color: #333333">*</span><span style="color: #0000DD; font-weight: bold">100</span> <span style="color: #888888"># = r kOhm / 2 *100</span>
    <span style="color: #008800; font-weight: bold">if</span> touch<span style="color: #333333">.</span>value: <span style="color: #888888">#touched</span>
        <span style="color: #008800; font-weight: bold">print</span>(<span style="background-color: #fff0f0">&quot;{</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">res</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">: &quot;</span><span style="color: #333333">+</span><span style="color: #007020">str</span>(<span style="color: #007020">int</span>(resistance))<span style="color: #333333">+</span><span style="background-color: #fff0f0">&quot;, </span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">touched</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">: </span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">True</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">}&quot;</span>)
        led[<span style="color: #0000DD; font-weight: bold">0</span>] <span style="color: #333333">=</span> (<span style="color: #0000DD; font-weight: bold">255</span>, <span style="color: #0000DD; font-weight: bold">0</span>, <span style="color: #0000DD; font-weight: bold">0</span>) <span style="color: #888888">#stay red</span>
    <span style="color: #008800; font-weight: bold">else</span>:
        <span style="color: #008800; font-weight: bold">print</span>(<span style="background-color: #fff0f0">&quot;{</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">res</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">: &quot;</span><span style="color: #333333">+</span><span style="color: #007020">str</span>(<span style="color: #007020">int</span>(resistance))<span style="color: #333333">+</span><span style="background-color: #fff0f0">&quot;, </span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">touched</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">: </span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">False</span><span style="color: #666666; font-weight: bold; background-color: #fff0f0">\&quot;</span><span style="background-color: #fff0f0">}&quot;</span>)
        led[<span style="color: #0000DD; font-weight: bold">0</span>] <span style="color: #333333">=</span> (<span style="color: #0000DD; font-weight: bold">0</span>, <span style="color: #0000DD; font-weight: bold">255</span>, <span style="color: #0000DD; font-weight: bold">0</span>) <span style="color: #888888">#stay green</span>
    time<span style="color: #333333">.</span>sleep(<span style="color: #6600EE; font-weight: bold">0.05</span>)
</pre></div>
            </v-col>
            <v-col md='12' sm='12'>
                <span>Gemma has built-in capacitive sensing capabilities on A0, so we attach a touch object to the pin, whose value will be “True” if touch is detected. Gemma calibrates the touch sensor as untouched upon startup, so it’s important to put the mushroom in place before running the code so that later touches can be separately detected. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
             <v-col md='12' sm='12'>
                <span class='section-subtitle'>Flask Server</span>
             </v-col>
            <v-col md='12' sm='12'>
                <span>The Flask server handles making the API call, loading the data, returning the data to the web client, and rendering the webpage. I only included the key parts of the code below.</span>
                <span>Gemma is communicating with Flask via Serial, in my case on COM9 with baud rate 9600, but this needs to be adapted to individual machine.  </span>
            </v-col>
            <v-col md='12' sm='12'>
                <!-- HTML generated using hilite.me -->
                <div class="highlighted-code" style="background: #ffffff; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #333333">......</span>
app <span style="color: #333333">=</span> Flask(__name__)

<span style="color: #888888">#https://stackoverflow.com/questions/57558398/importing-python-script-that-contain-serial-initialization-into-flask-app</span>
<span style="color: #008800; font-weight: bold">class</span> <span style="color: #BB0066; font-weight: bold">SerialProxy</span>:
    <span style="color: #333333">...</span>
    <span style="color: #008800; font-weight: bold">def</span> <span style="color: #0066BB; font-weight: bold">serial_function</span>(<span style="color: #007020">self</span>):
        <span style="color: #008800; font-weight: bold">if</span> <span style="color: #007020">self</span><span style="color: #333333">.</span>__serial <span style="color: #000000; font-weight: bold">is</span> <span style="color: #007020">None</span>:
            <span style="color: #007020">self</span><span style="color: #333333">.</span>__serial <span style="color: #333333">=</span> serial<span style="color: #333333">.</span>Serial(port<span style="color: #333333">=</span><span style="background-color: #fff0f0">&#39;COM9&#39;</span>, baudrate<span style="color: #333333">=</span><span style="color: #0000DD; font-weight: bold">9600</span>, timeout<span style="color: #333333">=.</span><span style="color: #0000DD; font-weight: bold">1</span>) 
        <span style="color: #333333">...</span>
        <span style="color: #333333">...</span>
            val <span style="color: #333333">=</span> (<span style="color: #007020">int</span>(val)<span style="color: #333333">-</span><span style="color: #0000DD; font-weight: bold">700</span>)<span style="color: #333333">/</span>(<span style="color: #0000DD; font-weight: bold">900</span><span style="color: #333333">-</span><span style="color: #0000DD; font-weight: bold">700</span>)
        <span style="color: #333333">...</span>
        <span style="color: #008800; font-weight: bold">if</span>(obj[<span style="background-color: #fff0f0">&quot;touched&quot;</span>]):
            <span style="color: #008800; font-weight: bold">return</span> val
        <span style="color: #008800; font-weight: bold">else</span>:
            <span style="color: #008800; font-weight: bold">return</span> <span style="color: #0000DD; font-weight: bold">1</span>

proxy <span style="color: #333333">=</span> SerialProxy()

<span style="color: #888888">#https://stackoverflow.com/questions/42810198/streaming-a-flask-response-that-relies-on-an-api-call</span>
<span style="color: #008800; font-weight: bold">def</span> <span style="color: #0066BB; font-weight: bold">stream_meat</span>():
    url <span style="color: #333333">=</span> <span style="background-color: #fff0f0">&quot;https://baconipsum.com/api/&quot;</span>
    <span style="color: #333333">...</span>
    _resp <span style="color: #333333">=</span> requests<span style="color: #333333">.</span>get(url,  params<span style="color: #333333">=</span>querystring, stream<span style="color: #333333">=</span><span style="color: #007020">True</span>)
    <span style="color: #333333">...</span>
    <span style="color: #008800; font-weight: bold">return</span> _resp<span style="color: #333333">.</span>iter_content(chunk_size<span style="color: #333333">=</span><span style="color: #0000DD; font-weight: bold">16</span><span style="color: #333333">*</span><span style="color: #0000DD; font-weight: bold">4</span>)

<span style="color: #888888">#https://stackoverflow.com/questions/31948285/display-data-streamed-from-a-flask-view-as-it-updates</span>
<span style="color: #555555; font-weight: bold">@app.route</span>(<span style="background-color: #fff0f0">&#39;/bar&#39;</span>) <span style="color: #888888"># render the content a url differnt from index</span>
<span style="color: #008800; font-weight: bold">def</span> <span style="color: #0066BB; font-weight: bold">content</span>():
    iterator <span style="color: #333333">=</span> stream_meat()
    <span style="color: #008800; font-weight: bold">def</span> <span style="color: #0066BB; font-weight: bold">inner</span>():
        <span style="color: #888888"># simulate a long process to watch</span>
        <span style="color: #008800; font-weight: bold">for</span> w <span style="color: #000000; font-weight: bold">in</span> iterator:
            time<span style="color: #333333">.</span>sleep(<span style="color: #6600EE; font-weight: bold">0.1</span><span style="color: #333333">+</span>proxy<span style="color: #333333">.</span>last_val<span style="color: #333333">/</span><span style="color: #0000DD; font-weight: bold">2</span>)
            <span style="color: #008800; font-weight: bold">yield</span> w
    <span style="color: #008800; font-weight: bold">return</span> app<span style="color: #333333">.</span>response_class(inner(), mimetype<span style="color: #333333">=</span><span style="background-color: #fff0f0">&#39;text/html&#39;</span>)

last_val <span style="color: #333333">=</span> <span style="color: #0000DD; font-weight: bold">0</span>
<span style="color: #555555; font-weight: bold">@app.route</span>(<span style="background-color: #fff0f0">&#39;/arduino&#39;</span>)
<span style="color: #008800; font-weight: bold">def</span> <span style="color: #0066BB; font-weight: bold">get_sensor</span>():
    val <span style="color: #333333">=</span> proxy<span style="color: #333333">.</span>serial_function()
<span style="color: #008800; font-weight: bold">return</span> <span style="color: #007020">str</span>(val<span style="color: #333333">*</span><span style="color: #0000DD; font-weight: bold">100</span>) 

<span style="color: #555555; font-weight: bold">@app.route</span>(<span style="background-color: #fff0f0">&#39;/foo-bar&#39;</span>)
<span style="color: #008800; font-weight: bold">def</span> <span style="color: #0066BB; font-weight: bold">stream_no_iframe</span>():
    <span style="color: #008800; font-weight: bold">return</span> render_template(<span style="background-color: #fff0f0">&#39;stream_template_no_iframe.html&#39;</span>) 
    <span style="color: #888888"># render a template at the index. The content will be embedded in this template</span>

app<span style="color: #333333">.</span>run(debug<span style="color: #333333">=</span><span style="color: #007020">True</span>)
</pre></div>
            </v-col>
        </v-row>
                <v-row class="wrapper">
             <v-col md='12' sm='12'>
                <span class='section-subtitle'>HTML and JavaScript</span>
             </v-col>
            <v-col md='12' sm='12'>
                <span>The following document is <pre>'stream_template_no_iframe.html'</pre>. Upon loading the page, a request is opened to stream data from the Flask server, and periodic checks of the mushroom values (touch and resistance)</span>
                <span>I've only included the relavent parts here as well.</span>
            </v-col>
            <v-col md='12' sm='12'>
                <!-- HTML generated using hilite.me -->
                <div class='highlighted-code' style="background: #ffffff; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;" v-pre><pre style="margin: 0; line-height: 125%"><span style="color: #557799">&lt;!DOCTYPE html&gt;</span>
<span style="color: #007700">&lt;body&gt;</span>
    <span style="color: #888888">&lt;!---https://stackoverflow.com/questions/31948285/display-data-streamed-from-a-flask-view-as-it-updates--&gt;</span>
...
<span style="color: #007700">&lt;script&gt;</span>
...
    <span style="color: #008800; font-weight: bold">var</span> xhr <span style="color: #333333">=</span> <span style="color: #008800; font-weight: bold">new</span> XMLHttpRequest();
    xhr.open(<span style="background-color: #fff0f0">&#39;GET&#39;</span>, <span style="background-color: #fff0f0">&quot;{{ url_for(&#39;content&#39;) }}&quot;</span>);
    xhr.send();    
...
    <span style="color: #008800; font-weight: bold">function</span> reqListener () {
        console.log(<span style="color: #008800; font-weight: bold">this</span>.responseText);
        ball.style.height <span style="color: #333333">=</span> (<span style="color: #008800; font-weight: bold">this</span>.responseText) <span style="color: #333333">+</span> <span style="background-color: #fff0f0">&quot;%&quot;</span>;
    }
    
    <span style="color: #008800; font-weight: bold">function</span> handleNewData() {
...
...
        position <span style="color: #333333">=</span> messages.length <span style="color: #333333">-</span> <span style="color: #0000DD; font-weight: bold">1</span>;
        bar.style.width <span style="color: #333333">=</span> (counter<span style="color: #333333">/</span>totalLength<span style="color: #333333">*</span><span style="color: #0000DD; font-weight: bold">100</span>).toFixed(<span style="color: #0000DD; font-weight: bold">2</span>) <span style="color: #333333">+</span> <span style="background-color: #fff0f0">&quot;%&quot;</span>;
...
    }

    <span style="color: #008800; font-weight: bold">var</span> timer;
    timer <span style="color: #333333">=</span> setInterval(<span style="color: #008800; font-weight: bold">function</span>() {
      ...
    }, <span style="color: #0000DD; font-weight: bold">100</span>);
<span style="color: #007700">&lt;/script&gt;</span>
<span style="color: #007700">&lt;style&gt;</span>
<span style="color: #333333">......</span>  
<span style="color: #007700">&lt;/style&gt;</span>
    
<span style="color: #007700">&lt;/body&gt;</span>
</pre></div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>The System in Action</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <v-img src="@/assets/Mushroom/let_go.png" max-height="350"></v-img>
                <span class="action-subtitle">When the mushroom is not pressed, the red box rests on the bar and the text loads slowly. </span>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img src="@/assets/Mushroom/pressed.png" max-height="350"></v-img>
                <span class="action-subtitle">When I pressed the mushroom, the text loads faster and the red box is floating high. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Discussion</span>
                <span>The Mushroom Data Valve is a speculative prototype that explores various forms of entanglement among living things, food, information, text, and humans. Without this device, the relations between these concepts form two linear paths: from living things to food, from information to text, and both are for human consumption. With the Mushroom Data Valve, the concepts are now further entangled in two loosely connected loops: the primary loop of living things, information, and humans, and the secondary loop of food, text, and humans. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>The Primary Loop</span>
                <v-img src="@/assets/Mushroom/primary-loop.png"></v-img>
                <span>The living thing in this project is chosen to be a mushroom. Mushrooms, as decomposers in nature, are vital actors in the ecological cycles. This project gives a mushroom an abstract role in the information loop. Sociologist Deborah Lupton argues that domestic data can be “lively” in the sense of data and humans shape each other [1], and, somewhat literally, the mushroom here is shaping the life of the information. When the mushroom is pressed down, it gives way to the flow of data. When it bounces back up, the flow is slowed down as if data had to flow pass the mushroom. The slowness of the information flow echoes the slow process of decomposing. In this sense, the mushroom is a form of data physicalization. </span>
                <span>The mushroom is not only a manifestation of data flow but also an Internet of Things (IoT) device. The abundance of information in recent years has profoundly reshaped human life, and IoT devices at home promise more and more convenience and productivity. The Mushroom Data Valve can be considered as an IoT device, but its purpose is the total opposite. The system, in its natural state, is characterized by slowness. This is a gesture against “smooth data”, data that lacks resistance [2]. By resisting the smoothness, the Valve could make people aware of the hidden work in the flowing data stream. When a user exerts force on the mushroom, the information flow is increase with the conscious effort of the user. The conscious effort could bring to light the distance the information and data travel, the protocols their travel is based on, and other hidden labor in retrieving the information.</span>
                <span>The mushroom in the Mushroom Data Valve is more than an arbitrary button to control the speed of the flow. As a living mass of cells, still breathing, it forms part of what I call the multispecies digital interface. In fact, the capacitive sensing button under the mushroom makes use of the dielectric property of a living thing to detect my touch on top of the mushroom. I consider this as a playful example of Donna Haraway’s “sympoiesis”, making-with, where the human-mushroom collaboration achieves the goal to enable data flow [3]. Although the goal here is not indispensable for either the dying mushroom or humans, to humans it might feel like compulsory work because data has become an indispensable part of everyday life. It is also interesting that the data flow the mushroom enables flows from the internet, a word that implies interconnection. The liveliness of the mushroom is an actor to weave in itself and its human partner to this net of things, physical and virtual. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>The Secondary Loop</span>
                <v-img src="@/assets/Mushroom/secondary-loop.png"></v-img>
                <span>Mushrooms link the primary loop above to the secondary loop of food, text, and humans, by being a kind of food itself. I bought these particular mushrooms at a grocery store 15-minute walk from home, and they are definitely intended to be eaten instead of becoming part of a digital interface. However, the act of eating a living thing has its own significance. Who grew the mushroom I used? How was it harvested? How many are actually eaten and how many will be wasted? These questions involve the otherwise invisible humans. Anna Tsing’s book, Mushroom at the End of the World, analyzes these questions and brings to light the extensive network of people and other living things behind the consumption of matsutake mushrooms [4]. Am I wasting the mushroom? The Mushroom Data Valve complicates the living things -food-human relationship by raising possible alternative values attached to the mushrooms, similar to the popularity of matsutake mushrooms as gifts. </span>
                <span>There are traces of other foods in this project. The random sentence generator server I found online generates sentences of random “meat words”, words that describe various kinds of uncooked or cooked meat, such as “beef” and “bacon”. When the page is filled with a hundred sentences of these random words, it becomes an intriguing object if considered under the toolmakers paradigm constructed by Reddy [5]. When faced with an entire screen of meat words, I found nothing meaningful, but when I sent a screenshot to my friend, she was annoyed because she was hungry but couldn’t have the food listed on the page. Therefore, as Reddy put it, words are signals of communication, and the feelings are not contained in the worlds but generated in our brains, so a hungry brain perceived different meaning from a brain with fewer needs. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Conclusion and Prospects</span>
                <span>By using a mushroom to control text download speed, the Mushroom Data Valve displays the entanglement among living things, food, information, text, and humans. To further articulate the concept, this project could turn into a mechanism to control the internet bandwidth of a computer with a mushroom. The mushroom in the project is the physical indicator of the data flow which also reduces the speed of the flow for human reflection. The human-mushroom relation goes beyond the consumer-food relation into a territory of interdependence. Finally, the placeholder text generator in this prototype pokes on the concept of words and meanings, revealing that meanings are constructed rather than contained in the words. </span>
                <span>This project depicts a step towards speculating on multispecies digital interfaces that bring the virtual world and the living world together. Is it possible to encourage taking care of companion species by incorporating them into home IoT devices, embedding them into an Internet of Living Things? In “The Camille Stories: Children of Compost”, human-animal symbionts gradually replace the unjoined humans [3]. Could the electronic devices that we carry around everyday become our living symbiotic partner? People already embed devices such as microchips and RFID tags into their pets, but the pets are not gateway to virtual information like the mushroom in this project. Will the goal be preserving existing species and adapt the digital world to them, or altering or even creating living creatures the is compatible with the digital world? </span>
                <span>The output of the current Mushroom Data Valve raises different questions. The memory of food constructed by reading the meat words was powerful enough to elicit feelings in a hungry person. Haraway also mentions that the orchids that have shapes like the female genitals of an extinct bee species capture the memory of the bees as interpreted by the orchids [3]. The memories will then be passed on to humans who see the flowers. Therefore, I see possibilities to consider how textual data we encounter every day could be tools to pass on memory of the existing species and to bring awareness to the troubles we are in.   </span>
                <span>Reddy claims that culture exists in living brains [5]. Technology advancements also exist in living human brains, but to live in harmony with the existing species, it may be time to remember them and involve them in the virtual world of information.  </span>
            </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References</span>
                <ol>
                    <li>Deborah Lupton. 2017. Feeling your data: Touch and making sense of personal digital data. New Media & Society 19, 10: 1599–1614. https://doi.org/10.1177/1461444817717515</li>
                    <li>Byung-Chul Han. 2018. Saving beauty. Polity Press, Cambridge, UK ; Malden, MA.</li>
                    <li>Donna Haraway. 2016. Staying with the Trouble: Making Kin in the Cthulucene. Experimental Futures: Technological Lives, Scientific Arts, Anthropological Voices. Duke University Press. Durham, NC. </li>
                    <li>Anna L. Tsing. 2015. The Mushroom at the End of the World. Princeton University Press. Princeton, NJ. </li>
                    <li>Michael Reddy. 1979. “The Conduit Metaphor: A Case of Frame Conflict in our Language about Language” in Ortony (ed.) Metaphor and Thought. Cambridge University Press. Cambridge, UK.</li>
                </ol>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
//import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
import ProjectHero from '@/components/ProjectHero.vue'

export default {
    name:"Mushroom",
    components:{
        //AnimatedPolygon
        ProjectHero,
    },
    data: ()=>{
        return {
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.compDesEntries){
                console.log("find theme color", entry)
                if(entry.title=="Mushroom Data Valve"){
                    return entry.titleBg
                }
            }
            return "#000000"
        },
        
    },
    mounted(){
        
    }
}
</script>
<style lang="scss" scoped>

.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(142, 36, 170, 0.3);
    }
}

div#bubble-space{
    position: relative;
    height: 500px;
    overflow: hidden;
}

div#bubble-space-demo{
    position: relative;
    height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    span{
        margin: 50px
    }
}

.illustration-icon{
    max-width: 150px;
    max-height: 150px;
    margin: auto;
}
.illustration{
    max-width: 250px;
    max-height: 250px;
    margin: auto;
}
.icon-text{
    display: inline-block;
}
.sam{
    max-width: 650px;
    margin: auto;
}

div.highlighted-code{
    span{
        display: initial;
    }
}

span.action-subtitle{
    margin-top: 15px;
}
</style>