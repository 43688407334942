<template>
    <div class='project-page'>
        <ProjectHero :src="'Welding/MFI_demo_compressed.jpg'"
        :sides="20"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                Intelligent Welding
            </template>
            <template v-slot:subtitle>
                Co-Designed MR Platform with ML-Augmented Embodied Skill Training
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Overview</span>
                <span class="project-statement-text">My team and I codesigned an intelligent Mixed-Reality (MR) system with the Industrial Arts Workshop in Pittsburgh, which hosts welding training programs for high school students in underserved communities. This system has three components: AR welding guides for movement training; a machine learning network that provides error warnings based on sonic cues from welding; a mindful breathing promotion interface to reduce environmental stimulation and stress from welding. I worked on the machine learning network as well as breath sensing. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: Zhenfang Chen*, Tate Johnson*, Andrew Knowles*, Ann Li*, Semina Yi*, Yumeng Zhuang*, Dina El-Zanfaly, and Daragh Byrne</span>
                <span>* Contributions:</span>
                <span><b>MR Development:</b> Andrew Knowles, Semina Yi</span>
                <span><b>Co-Design Workshop Planning:</b> Ann Li, Semina Yi</span>
                <span><b>ML Augmentation: Yumeng Zhuang </b>, Zhenfang Chen</span>
                <span><b>Mindful Breathing:</b> Semina Yi, <b>Yumeng Zhuang</b></span>
                <span><b>Product 3D Modeling and Corresponding Author:</b> Tate Johnson</span>
                <span>Thumbnail image on the project page is credited to Tate Johnson. </span>
                <span>Time: May 2023 – Present</span>
                <span>Published at ACM Conference on Interactive Surfaces and Spaces as Demonstration. <b>[Best Demo Award]</b> <a target="_blank" href="https://doi.org/10.1145/3626485.3626544">[Link]</a></span>
                <span>Accepted by at ACM Conference on Tangible Embedded and Embodied Interaction as Pictorial.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Background: IAW</span>
            </v-col>
            <v-col md='4' sm='6'>
                <v-img src="@/assets/Welding/IAW_outside_cropped.jpg"></v-img>
            </v-col>
            <v-col md='4' sm='6'>
                <v-img src="@/assets/Welding/IAW_sticker_long.jpg"></v-img>
            </v-col>
            <v-col md='4' sm='6'>
                <v-img src="@/assets/Welding/IAW_inside_cropped.jpg"></v-img>
            </v-col>
            <v-col md='12' sm='12'>
                <span>The Industrial Arts Workshop <a target="_blank" href="https://iawpgh.org/">(IAW) </a> is a nonprofit organization offering Hazelwood and broader Pittsburgh-area youth and adults welding and metal work training for making metal art sculptures. Through hands-on making, the programs at IAW aim to bring together an experience of community engagement, workforce preparation and arts education.  </span>

                <span> recruits most of their students from local high schools to enrich their extra-curricular experiences, which is often inaccessible to families with lower socio-economic status. The curriculum at IAW is supported by professional artists and welders, as well as student interns. </span>
            </v-col>
            <v-col md='9' sm='12'>
                <v-img max-height="400"  contain src="@/assets/Welding/workshop_table.jpg"></v-img>
            </v-col>
            <v-col md='9' sm='12'>
                <span>We conducted co-design workshops with IAW to identify the needs of the welding instructors and students.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>AR Guides</span>
            </v-col>
            <v-col md='4' sm='6'>
                <v-img src="@/assets/Welding/headset_board_helmet_compressed.jpg"></v-img>
            </v-col>
            <v-col md='4' sm='6'>
                <v-img src="@/assets/Welding/lab_weld_green_angle_cropped.jpg"></v-img>
            </v-col>
            <v-col md='4' sm='12'>
                <span>We developed an AR environment within a customized helmet which offers real-time in-situ guides and performance indicators for student practice. The slider on the left shows the distance between the tip of the welding gun and the welding line (dashed) and the green angle is the indicator that the travel angle of the gun is correct. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='8' sm='12'>
                <span class='section-title'>Sonic Feedback and ML Error Detection</span>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/james_listening.jpeg"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='8' sm='12'>
                <span>We learned from online resources that welding sound can guide the practitioner to adjust movements and machine settings. I invited participants to listen to the welding videos and they confirmed that the sonic cues contain valuable information. Therefore, we developed a notification system based on sonic cues.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Data Collection</span>
                <span>I collected 20 minutes of welding sounds from Zhenfang Chen and a CMU welder under different incorrect welder settings: accurate setting, far tip distance, wire speed too fast, wire speed too slow, no shielding gas, amperage too high, and amperage too low. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/techspark_weld1_cropped.jpg"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/techspark_weld2_cropped.jpg"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/welder1.jpg"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/welder1_weld_compressed.jpg"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Microcontroller ML</span>
                <span>I trained a network on Edge Impulse and deployed it to an ESP32-based Seeed microcontroller.</span>
                <span>I extracted Mel-filterbank energy features from every second of the audio data we collected, resulting in a tensor of 40 filters x 48 time frames.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/model_layers.jpg"></v-img>
            </v-col>
            <v-col md='12' sm='12'>
                <span> The subsequent layers are: 1) an 1D convolution with 8 neurons and a kernel size of 3; 2) a max pooling layer with size 2 and stride 2; 3) a dropout layer with 0.25 rate; 4) an 1D convolution layer with 16 neurons and a kernel size of 3; 6) a max pooling layer with size 2 and stride 2; 5) another dropout layer of 0.25 rate; 6) a layer to flatten the outputs; and finally 7) an output layer of 7 classes.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/new_7.2.png"></v-img>
            </v-col>
            <v-col md='12' sm='12'>
                <span>Overall, our model achieved an accuracy of 77.62% on the test dataset under a confidence threshold of 0.4. Based on the confusion matrix in the table above, we observed that the model often confused accurate welding settings with the setting of too high amperage, due to the fact that the smaller welder we sampled our data from could not exceed the recommended amperage by a noticeable amount. This result validated that sound contains useful information for welding training.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Mindful Breathing</span>
                <span>I programmed a breath sensor to remind students to breathe during welding. This was also inspired by IAW’s existing mindfulness practice. The breath sensor measures breathing rate based on wind speed variations and passes that information to display on the AR headset. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/seed_aneometor_compressed.jpg"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class='mt-5' max-height="400" contain src="@/assets/Welding/page8_plot.png"></v-img>
            </v-col>
        </v-row>


        <v-divider></v-divider>

        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Future Work</span>
                <span>We are still iterating on the integration of these three major components. Next steps will also include evaluating the system with students and Instructors at IAW. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Publication</span>
                <span>Tate Johnson, Ann Li, Andrew Knowles, Zhenfang Chen, Semina Yi, Yumeng Zhuang, Dina El-Zanfaly, and Daragh Byrne. 2023. Augmenting Welding Training: An XR Platform to Foster Muscle Memory and Mindfulness for Skills Development. In <i>Companion Proceedings of the 2023 Conference on Interactive Surfaces and Spaces (ISS Companion ‘23)</i>. Association for Computing Machinery, New York, NY, USA, 61–64. <a target="_blank" href="https://doi.org/10.1145/3626485.3626544">https://doi.org/10.1145/3626485.3626544</a></span>
            </v-col>
        </v-row>



    </div>
</template>

<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"Welding",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.interactionEntries){
                console.log("find theme color", entry)
                if(entry.title=="Intelligent Welding"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>

<style scoped>
.simulation-img{
    max-width: 600px;
    margin: auto;
}
.chart-img{
    max-width: 500px;
    margin: auto;
}
</style>