<template>
    <div>
      <svg :width="width" :height="width">
        <polygon :points="points" :fill="fillColor"></polygon>
        <circle :cx="width/2" :cy="width/2" :r="width/2*0.9"></circle>
      </svg>
    </div>
</template>
<script>
import { TimelineLite } from 'gsap'

export default {
    name: "AnimatedPolygon",
     props: {
        sides: {
            type: Number,
            required: true
        },
        updateInterval: {
            type: Number, 
            required: true
        },
        width: {
            type: Number, 
            required: true
        },
        minRadius: {
            type: Number,
            required: true
        },
        fillColor: {
            type:String,
            default: '#35495e'
        },
        //style: String
    },
    data: function() {
        //var defaultSides = 10;
        var stats = Array.apply(null, { length: this.sides }).map(
            () => {
                return this.width/2;
            }
        );
        return {
            stats: stats,
            points: this.generatePoints(stats),
            //sides: defaultSides,
            //minRadius: 50,
            interval: null,
            //updateInterval: 500
        };
    },
    watch: {
        stats: function(newStats) {
            const timeline = new TimelineLite()
            timeline.to(this.$data, this.updateInterval / 1000, {
                points: this.generatePoints(newStats)
            });
        },
        updateInterval: function() {
            this.resetInterval();
        }
    },
    mounted: function() {
        this.resetInterval();
    },
    methods: {
        randomizeStats: function() {
            var vm = this;
            this.stats = this.stats.map(function() {
                return vm.newRandomValue();
            });
        },
        newRandomValue: function() {
            return Math.ceil(
                this.minRadius*this.width/2 + Math.random() * (this.width/2 - this.minRadius*this.width/2)
            );
        },
        resetInterval: function() {
            var vm = this;
            clearInterval(this.interval);
            this.randomizeStats();
            this.interval = setInterval(function() {
                vm.randomizeStats();
            }, this.updateInterval);
        },
        valueToPoint(value, index, total) {
            var x = 0;
            var y = -value * 0.9;
            var angle = ((Math.PI * 2) / total) * index;
            var cos = Math.cos(angle);
            var sin = Math.sin(angle);
            var tx = x * cos - y * sin + this.width/2;
            var ty = x * sin + y * cos + this.width/2;
            return { x: tx, y: ty };
        },
        generatePoints(stats) {
            var total = stats.length;
            return stats
                .map((stat, index)=> {
                var point = this.valueToPoint(stat, index, total);
                return point.x + "," + point.y;
                })
                .join(" ");
        }
    },
}

</script>
<style lang="scss" scoped>
svg {
    display: block;
}
//polygon {
    //fill: #41b883;
//}
circle {
    fill: transparent;
    stroke: #555555;
}

</style>
