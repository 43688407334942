<template>
    <div class='project-page'>
        <ProjectHero
        :sides="15"  :min-radius="0.3" :theme-color="themeColor">
            <template v-slot:title>
                Vitruvian Woman V2.02
            </template>
            <template v-slot:subtitle>
                Woman at the Push of a Button
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class='wrapper'>
            <v-col>
                <v-img src="@/assets/art/Woman-all.png" class='painting-img' contain></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Statement</span>
                <span class="project-statement-text">Vitruvian Woman V2.02 is a product set in 3032 when all is wanted from a woman can be abstracted and mechanized into machines, and more specifically, machines with buttons that would function “like a woman” with just one push. As we observed that buttons have become ubiquitous today, we start to notice their inherent dangers. Light switches conceal the complexity of the electrical engineering involved; phone call buttons conceal the distance between the two individuals. Even the phrase “nuclear button”, which is grossly simplified, suggests that buttons serve the privileged to bring a sense of control over the work and labor involved in making things actually happen. Our project materializes the desire and control to <i>objectify women</i> into buttons at the disposal of the future rich. From sexual arousal to house chore help, Vitruvian Woman V2.02 meets the stereotypical expectation of a woman, which we hope won’t exist by 3032. </span>
            </v-col>
        </v-row>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Contributions</span>
                <ul>
                    <li>LipPulse model and artifact – <b>Yumeng Zhuang</b></li>
                    <li>InstaHands – Thien Le</li>
                    <li>MilkyMilkers – Tabeer Tariq</li>
                    <li>VaChine – Suzie Liu</li>
                    <li>VendingWomb – Suzie Liu</li>
                    <li>ButtBounce – Catherine Liu</li>
                    <li>Line drawings compiled by Suzie Liu</li>
                </ul>
                <span>October 2022, as Project 02 for <b>Possibistic Design</b> taught by Sinan Goral at Carnegie Mellon University.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'> 
                <span>This manual introduces the latest features of the <b>Vitruvian Woman V2.02</b> model.</span>
            </v-col>
            <v-col md="6" sm='12'>
                <v-img src="@/assets/art/WomanButton/woman-square.png"></v-img>
            </v-col>
            <v-col md='6' sm='12'> 
                <ol>
                    <li>LipPulse&trade;</li>
                    <li>InstaHands&trade;</li>
                    <li>MilkyMilkers&trade;</li>
                    <li>VaChine&trade;</li>
                    <li>VendingWomb&trade;</li>
                    <li>ButtBounce&trade;</li>
                </ol>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title default-case'>MVP Release V2.02a - LipPulse&trade;</span>
            </v-col>
            <v-col md='4' sm='12'>  
                <v-img src="@/assets/art/WomanButton/frame0.jpg"></v-img>
            </v-col>
            <v-col md="4" sm='12'>
                <v-img src="@/assets/art/WomanButton/movie-small.gif"></v-img>
            </v-col>
            <v-col md='4' sm='12'>  
                <v-img src="@/assets/art/WomanButton/frame2300.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-subtitle'>MVP Release V2.02a - LipPulse&trade;</span>
                <span>The lips here needs to be replaced after 10 sessions: the life of a woman can be drained by fulfilling the desires of other people, but she as a button could easily be replaced.</span>
            </v-col>
        </v-row>

        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title default-case'>V2.02 - LipPulse&trade;</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>Attach lips to the <span class='btn-highlight'>button</span> on top.</span>
                <span>LipPulse should move with your lips during your session.</span>
                <span>LipPulse retracts into the box after 10 sessions. Kindly request for replacement to ensure maximum freshness and enjoyment.</span>
            </v-col>
            <v-col lg="4" md="6" sm='12'>
                <v-img src="@/assets/art/WomanButton/LipPulse.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title default-case'>V2.02 - InstaHands&trade;</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>Caress or touch the <span class='btn-highlight'>button</span> on top.</span>
                <span>Objects of desire (food, clothes, etc.) are expected to extrude onto the belt and drop from the window below.</span>
            </v-col>
            <v-col lg="4" md="6" sm='12'>
                <v-img src="@/assets/art/WomanButton/InstaHands.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title default-case'>V2.02 - MilkyMilkers&trade;</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>Squeeze the <span class='btn-highlight'>buttons</span> on top.</span>
                <span>A suction mechanism drains out milk at anytime of the day according to requirement. Get instant access to breast feeding.</span>
            </v-col>
            <v-col lg="4" md="6" sm='12'>
                <v-img src="@/assets/art/WomanButton/MilkyMilkers.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title default-case'>V2.02 - VaChine&trade;</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>Insert into the front opening to begin.</span>
                <span>Exert pressure on either or both side rubber pad <span class='btn-highlight'>buttons</span> to customize contraction intensity.</span>
            </v-col>
            <v-col lg="4" md="6" sm='12'>
                <v-img src="@/assets/art/WomanButton/VaChine.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title default-case'>V2.02 - VendingWomb&trade;</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>Press the front <span class='btn-highlight'>button</span>.</span>
                <span>A baby is expected to drop within 10 seconds through the opening right below the button.</span>
            </v-col>
            <v-col lg="4" md="6" sm='12'>
                <v-img src="@/assets/art/WomanButton/VendingWomb.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title default-case'>V2.02 - ButtBounce&trade;</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>Stroke, squeeze, or smack the <span class='btn-highlight'>buttons</span>.</span>
                <span>ButtBounce reacts by jiggling and pulsing at various intensity.</span>
            </v-col>
            <v-col lg="4" md="6" sm='12'>
                <v-img src="@/assets/art/WomanButton/ButtBounce.png"></v-img>
            </v-col>
        </v-row>


    </div>
</template>
<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"WomanButton",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.artEntries){
                console.log("find theme color", entry)
                if(entry.title=="Vitruvian Woman V2.02"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>

<style lang="scss" scoped>

span.default-case{
    text-transform: none;
}

.wrapper span.btn-highlight{
    display: initial;
    color: rgb(175, 18, 18);
}
</style>