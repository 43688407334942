<template>
    <div class='project-page'>
        <ProjectHero
        :sides="15"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                Eye Cape
            </template>
            <template v-slot:subtitle>
                Kinetic Costume Featuring Eyeballs
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class='wrapper'>
            <v-col>
                <v-img src="@/assets/art/Eyeballs/hero_compressed.jpg" class='painting-img' contain></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Statement</span>
                <span class="project-statement-text">The Eye Cape was part of the end-of-semester show featuring our class, Kinetic Fabrics, the inflatables class, the animation class, and the Tartan Tuba Band. Knowing the theme of phantasmagoria, we thought of eyeballs because they are associated with intimacy for being the vital organ that gives us the most information, but also fear of being watched as symbols of surveillance. The eyeballs were supposed to pop to scare people, but due to the slow inflation, the final effect was more like a mesmerizing show where they emerge and submerge slowly. </span>
                <span class="project-statement-text">After coming up with the pouch-in-bowl design for the eyeballs, we realized that each of the bulky eyeballs took up a lot of space under the surface, so a wearable piece needed to be wide and loose enough to accommodate them. A cape was ideal because it had more space, was visible, and large enough to layout multiple eyes, which we finally have 8.</span> 
                <span class="project-statement-text">The colored felt eyes were added to decorate the cape and also as a form of distraction from the black-and-white design of the eyeballs. We kept the vest mostly white to keep the audience focused on the cape. However, the colorful eyes were still distracting so we added a black felt ring around each of the eyeballs, and put a small piece of sequin fabric on the pupil as well. This created a nice balance of the magical, disorienting colored yet flat eyes, and the black and white popping eyeballs. </span>
                <span class="project-statement-text">The music we asked our tuba player to play was not any particular piece, but a sinuous melody of a minor key that aroused feelings of uneasiness. </span>
            </v-col>
        </v-row>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Contributions</span>
                <ul>
                    <li>Eyeball structures and code – <b>Yumeng Zhuang</b></li>
                    <li>Vest and cape – Sophia Huang</li>
                </ul>
                <span>April 2022, as Final Project for <b>Kinetic Fabrics</b> taught by Prof. Olivia Robinson and Prof. Garth Zeglin at Carnegie Mellon University.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Eyeball Making</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>Eyeballs are made by attaching a foam ball to air pouches fitted inside a bowl. The air pouches inflate to fill the bowl, and thus push the foam ball out of the opening of the bowl.</span>
            </v-col>
            <v-col md="6" sm='12'>
                <v-img src="@/assets/art/Eyeballs/single-eye_compressed.jpg"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Tubing and Motor Pumps</span>
            </v-col>
            <v-col md='6' sm='12'>  
                <span>The pumps are programmed such that they inflate the pouches to 70% of the maximum volume, measured by a single pump in seconds. There were three pairs of pumps, one for inflation and one for deflation. Each pair is chained to 2, 3, 3 pouches, respectively.</span>
            </v-col>
            <v-col md="6" sm='12'>
                <v-img src="@/assets/art/Eyeballs/tubing-pumps.jpg"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Making the Cape and Vest</span>
            </v-col>
            <v-col md='4' sm='12'>  
                <span>The vest and cape were made entirely out of canvas material and had ironed on felt added to it.</span>
                <span>To make the vest, we took measurements of our tuba player Hunter and used them to make a vest pattern. We then cut the canvas pieces using the pattern, added a collar and a zipper, and we finished off the ends.</span>
                <span>We cut out holes for the eyeballs and ironed on felt eyes around the holes, adding the black circle last.</span>
            </v-col>
            <v-col md="4" sm='12'>
                <v-img src="@/assets/art/Eyeballs/vest.png"></v-img>
            </v-col>
            <v-col md="4" sm='12'>
                <v-img src="@/assets/art/Eyeballs/close-up-eyes.jpg"></v-img>
            </v-col>
            <v-col md="12" sm='12'>
                <v-img src="@/assets/art/Eyeballs/final-cape.jpg"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Performance</span>
            </v-col>
            <v-col md="12" sm='12'>
                <v-img src="@/assets/art/Eyeballs/performance_compressed.jpg"></v-img>
            </v-col>
            <v-col sm='12'>
                <span>Link to the video of the Eye Cape can be found here: <a target="_blank" href="https://youtu.be/Tt5fOs8PtkE">https://youtu.be/Tt5fOs8PtkE</a></span>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"Eyeballs",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.artEntries){
                console.log("find theme color", entry)
                if(entry.title=="Eyeballs"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>