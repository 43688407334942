import Vue from 'vue'
import Vuex from 'vuex'
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tags:['art', 'design', 'physics', 'tangible', 'speculative', 'robotics', 'MR', "ML"],
    //x-darken-1: green, purple, blue, lime, pink, brown, cyan700, red900
    tagColors: ['#43A047', '#8E24AA', '#1E88E5', '#C0CA33', '#D81B60', '#6D4C41', "#0097A7", "#B71C1C"],
    interactionEntries:[
      ///HCI
      {
        linkTo:'/Welding', //Done
        title:'Intelligent Welding', 
        titleBg: colors.blue.base,
        subtitle:"Mixed Reality and ML-Enhanced Welding Training System",
        subtitleBg: 'rgba(30, 136, 229, 0.9)', 
        bg:"Welding/9.2.v3_erased.jpg", 
        col: 4,
        tags:['MR', "ML"]},
      {
        linkTo:'/EchoMask', //Done
        title:'Echo Mask', 
        titleBg: colors.orange.base,
        subtitle:"A Discursive Approach to Tangible Rumination",
        subtitleBg: 'rgba(165, 141, 22, 0.9)', 
        bg:"EchoMask/card_still.jpg", 
        col: 4,
        tags:['speculative', 'tangible', "ML"]},
      {
        linkTo:'/ReconfigMM', //Done
        title:'Reconfigurable Metamaterial', 
        titleBg: colors.amber.base,
        subtitle:"Metameterial Sheet with Customizable Elasticity",
        subtitleBg: 'rgb(230, 212, 72, 0.7)', 
        bg:"ReconfigMM/reconfMM-side-with-lid-small.png", 
        col: 4,
        tags:['physics', 'tangible']},
      {
        linkTo:'/WallInspection',
        title:'Robotic Wall Inspection', 
        titleBg: colors.purple.lighten1,
        subtitle:"Computational Framework For Robotic Quality Assessment and Management In Construction",
        subtitleBg: 'rgba(96, 125, 139, 0.7)', 
        bg:"WallInspection/pink-blue-cover.png", 
        col: 4,
        tags:['physics', 'robotics']},

      {
        linkTo:'/Mushroom',
        title:'Mushroom Data Valve', 
        titleBg: colors.blueGrey.base,
        subtitle:"Mushroom Controlled Text Streaming Device",
        subtitleBg: 'rgba(96, 125, 139, 0.7)', 
        bg:"Mushroom/mushroom-stacked.png", 
        col: 4,
        tags:['speculative', 'tangible']},
      {
        linkTo:'/NoiseDecoder', //Done
        title:'Noise Decoder', 
        titleBg: colors.blueGrey.base,
        subtitle:"Concrete Poetry of Fleeting Sounds",
        subtitleBg: 'rgba(96, 125, 139, 0.5)', 
        bg:"NoiseDecoder/rolls-2-cover.png", 
        col: 4,
        tags:['speculative', 'tangible', 'ML']},
      {
        linkTo:'/PTCam', //Done
        title:'PTCam', 
        titleBg: '#504de5',
        subtitle:"AI-Powered Shoulder Health Assessment", 
        subtitleBg: "rgba(80, 77, 229, 0.5)",
        bg:'PTCam/final-mockup_compressed.jpg', 
        col: 4,
        tags:['design',]},
      {
        linkTo:'/bubble-space', //Done
        title:'bubble space', 
        titleBg: colors.purple.darken1,
        subtitle:"Fun Visual Tool for Mood Tracking",
        subtitleBg: 'rgba(142, 36, 170, 0.5)', 
        bg:"bubble-space/milder_no_chart.png", 
        col: 4,
        tags:['design', ]},
    ],
    compDesEntries:[
      ///computational design
      
    ],
      ///art design
    physicsEntries:[
      {
        linkTo:'/EinzelLens', //Done
        title:'Einzel Lens Optimization', 
        titleBg: colors.red.darken4,
        subtitle:"In Electron Diagnostics for Emission Cathodes",
        subtitleBg: 'rgba(183, 28, 28, 0.5)', 
        bg:"EinzelLens/einzel_CAD.png", 
        col: 4,
        tags:['physics',]},
      {
        linkTo:'/CPT', //Done
        title:'CPT Diagnostics', 
        titleBg: colors.blueGrey.base,
        subtitle:"Spectrum Reconstruction Using EM Algorithm and Machine Learning",
        subtitleBg: 'rgba(96, 125, 139, 0.7)', 
        bg:"CPT/cpt_new-white.png", 
        col: 4,
        tags:['physics', ]},
      
      {
        linkTo:'/CompostPile', //Done
        title:'Compost Pile', 
        titleBg: colors.brown.darken1,
        subtitle:"Simulation for Compost Piles and Optimization of Shape",
        subtitleBg: 'rgba(109, 76, 65, 1)', 
        bg:"CompostPile/temp_3d_w_axes.png", 
        col: 4,
        tags:['physics', ]},
      ],
      //---------------work----------------
      workEntries:[
      {
        linkTo:'/MeshML', //Done
        identifier: 'Roblox, San Mateo, CA, 2022',
        title:'Machine Learning on Mesh Models', 
        titleBg: colors.blue.base,
        subtitle:"Mechine Learning on Mesh Models as Animation Aid",
        subtitleBg: 'rgba(144, 202, 249, 0.7)', 
        bg:"RigNet/Rig-Untitled-1.png", 
        col: 12,
        tags:['animation', 'ml', 'coding']},
      {
        linkTo:'/CKGT', //Done
        identifier: 'Evecom, Fujian, China, 2021',
        title:'Knowledge Graph to Text Translation', 
        titleBg: colors.blue.base,
        subtitle:"Descriptive Text Generation Based on Knowledge Graphs in Chinese",
        subtitleBg: 'rgba(144, 202, 249, 0.7)', 
        bg:"CKGT/CKGT-main-white.png", 
        col: 12,
        tags:['nlp', 'coding']},
      
      ],
      //---------------art---------------------
      artEntries:[
      {
        linkTo:'/PryMe', //Done
        title:'Pry Me Open', 
        titleBg: colors.pink.base,
        subtitle:"May Touch Dominate Sight",
        subtitleBg: 'rgba(170, 170, 170, 0.5)', 
        bg:"art/PryMe-White.jpg", 
        col: 2,
        tags:['speculative', 'design']},
      {
        linkTo:'/WomanButton',
        title:'Vitruvian Woman V2.02', 
        titleBg: colors.red.darken1,
        subtitle:"Woman at the Push of a Button",
        subtitleBg: 'rgba(142, 36, 170, 0.5)', 
        bg:"art/WomanButton/frame0.jpg", 
        col: 2,
        tags:['speculative', 'design']},
      {
        linkTo:'/Eyeballs',
        title:'Eye Cape', 
        titleBg: colors.grey.darken3,
        subtitle:"Kinetic Costume Featuring Eyeballs",
        subtitleBg: 'rgba(33, 33, 33, 0.5)', 
        bg:"art/Eyeballs/thumbnail.png", 
        col: 2,
        tags:['art']},
      {
        linkTo:'/Translator',
        title:'Pocket Translator', 
        titleBg: colors.red.darken3,
        subtitle:"Pocket Translator for Chinese International Students",
        subtitleBg: 'rgba(33, 33, 33, 0.5)', 
        bg:"art/Translator/statement-2.jpg", 
        col: 2,
        tags:['speculative', 'design']},

      // {
      //   linkTo:'/Mallard', //Done
      //   title:'Mallard', 
      //   titleBg: colors.grey.darken4,
      //   subtitle:"Portrait of a Mallard Duck",
      //   subtitleBg: 'rgba(33, 33, 33, 0.5)', 
      //   bg:"art/Mallard.jpg", 
      //   col: 2,
      //   tags:['art']},

      // {
      //   linkTo:'/CatsCradle', //Done
      //   title:'Cat\'s Cradle', 
      //   titleBg: colors.green.base,
      //   subtitle:"Born through Cats",
      //   subtitleBg: 'rgba(76, 175, 80, 0.5)', 
      //   bg:"art/CatsCradle.png", 
      //   col: 3,
      //   tags:['art']},
      // {
      //   linkTo:'/AnimalsKitchen', //Done
      //   title:'Animals in the Kitchen', 
      //   titleBg: colors.blue.base,
      //   subtitle:"Magical Moments in Everyday Life",
      //   subtitleBg: 'rgba(33, 150, 243, 0.5)', 
      //   bg:"art/bear_in_fridge.png", 
      //   col: 3,
      //   tags:['art']},
      // {
      //   linkTo:'/MiscPaintings', //Done
      //   title:'Miscellaneous Paintings', 
      //   titleBg: colors.grey.base,
      //   subtitle:"A Collection",
      //   subtitleBg: 'rgba(33, 33, 33, 0.5)', 
      //   bg:"art/house.png", 
      //   col: 3,
      //   tags:['art']},
      
    ],
    //-----------writings------------
    writingEntries:[{
      linkTo:'/GardenCat',
      identifier: 'July - August 2022',
      title:'Garden Cat', 
      titleBg: colors.grey.darken4,
      subtitle:"Fiction",
      subtitleBg: 'rgba(33, 33, 33, 0.5)', 
      bg:"art/Mallard.jpg", 
      col: 12,
      tags:['writing']}
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
