<template>
    <div class='project-page'>
        <ProjectHero :src="'CompostPile/temp_3d_dark.png'"
        :sides="4"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                Compost Pile
            </template>
            <template v-slot:subtitle>
                A Simulation for Compost Piles and Optimization of Shape
            </template>
        </ProjectHero>
        <v-row class="wrapper">
            <span>A 3D visualization of temperature distribution of a 0.7 by 0.7 by 0.4m compost pile after 3 days. </span>
        </v-row>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">This project features a piece of code to find the optimal size of a compost pile given the complex dynamics of composting organic matter, as outlined by Problem B in University Physics Competition 2018. Temperature, aeration, humidity, and the types of waste material in the compost must form a delicate balance so that the bacteria can grow at a desired rate. There were so many factors to consider in 48 hours that David and I had to make careful selections based on importance. With the simulation coded, we aimed for the lowest percentage of mass remaining after 30 days. The best choice we found is a pile with a square base of 0.7 meters and a height of 0.4 meters, which leaves 34.94% of its original mass after 30 days. This shape is manageable in a standard backyard, so we hope that our optimization can contribute to the scientific support of a greener lifestyle. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: <b>Yumeng Zhuang</b>, David Su</span>
                <span>My role: calculating the collapsing pile, Python programming</span>
                <span>Time: November 2018</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper text-center">
            <v-col md='12' sm='12'>
                <span class='section-title'>What is composting?</span>
                <span>Composting uses biological processes to break down organic waste and turn it into a soil fertilizer. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper text-center">
            <v-col md='12' sm='12'>
                <span class='section-title'>Why size matters?</span>
                <span>A variety of factors affects the efficiency of the composting process, including <b>temperature</b>, <b>aeration</b>, <b>humidity</b>, <b>waste components</b>, and others.</span>
                <span>Since the pile size affects its internal pressure, we expect more air voids in smaller piles, which promotes aeration. </span>
                <span>On the other hand, we expect bigger and taller piles to have a large volume to surface area ratio, thus maintaining the heat which is crucial for bacteria activity.</span>
            </v-col>
            <v-col md='12' sm='12' class='d-flex'>
                <v-img class="illustration-icon"
                    src="@/assets/CompostPile/icons_compost icon.png"
                ></v-img>
                <v-img class="illustration-icon"
                    src="@/assets/CompostPile/icons_air bubbles.png"
                ></v-img>
                <v-img class="illustration-icon"
                    src="@/assets/CompostPile/icons_retain heat.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Assumptions</span>
                <ul>
                    <li>The pile is packed into a <b>rectangular pile</b>. We only want to study the effect of scaling to the efficiency, so the geometry of the pile is irrelevant.</li>
                    <li>The pile is exposed on <b>five sides to air and one side to the ground</b>. </li>
                    <li>The <b>ratio of mass of dry solids to total mass is constant</b> throughout the extent of the composting process. </li>
                    <li>Heat transfer within the pile occurs <b>only by conduction</b>. The air voids within the compost material are too small for a convective circulation to develop.</li>
                </ul>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Mechanical Properties and the Collapsing Pile</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>The pile is composed of three forms of matter: <b>gas</b>, <b>liquid</b>, and <b>solid</b>. </span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img class="illustration-icon"
                    src="@/assets/CompostPile/icons_fractions.png"
                ></v-img>
            </v-col>
            <v-col md='8' sm='12'>
                <v-img class="illustration-equation"
                    src="@/assets/CompostPile/fractions.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>Due to the compression of gravity, the pile of compost has different <b>bulk density</b> ρ = M/(AH) as a function of the total height of the pile (Ginkel, Raats, and Van Haneghem, 1999).</span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img class="illustration-icon"
                    src="@/assets/CompostPile/icons_compression.png"
                ></v-img>
            </v-col>
            <v-col md='8' sm='12'>
                <v-img class="illustration-equation-small"
                    src="@/assets/CompostPile/pMAH.png"
                ></v-img>
                <v-img class="illustration-equation"
                    src="@/assets/CompostPile/MH.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Compost Dynamics</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>We modeled the consumption of this mass by considering the spatial and temporal evolution of the <b>biomass density</b> X, the <b>bulk oxygen concentration</b> O2, and the <b>temperature</b> T (Lin et all, 2008). </span>
                <span>We have differential equations to link the above-mentioned quantities with each other.</span>
            </v-col>
            <v-col md='4' class="d-none d-md-block">
            </v-col>
            <v-col md='8' sm='12'>
                <v-img class="illustration-equation-bigger"
                    src="@/assets/CompostPile/dOdtdTdt.png"
                ></v-img>
                <v-img class="illustration-equation-biggest"
                    src="@/assets/CompostPile/RgRd.png"
                ></v-img>
                <v-img class="illustration-equation"
                    src="@/assets/CompostPile/dMdt.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <span class='section-title'>Simulation and Optimization</span>
                <ul>
                    <li>Our grid is made up of <b>7 cm cubes</b> and each time step is <b>30 seconds</b>. </li> 
                    <li><b>Finite difference Laplacian and Euler’s method</b> are used to evolve the system in Python.  </li>
                    <li>We use <b>grid search</b> to find the optimal parameters: the base of the pile ranges from 0.3m to 0.9m, and the height of the pile ranges from 0.1m to 1.5m.  </li>
                    <li>The simulation is cut off at <b>30 days</b> and we compare the remaining percentage of mass.  </li>
                    <li>The optimal size is a <b>0.7m square base and a 0.4m height</b>, as shown in the surface plot. </li>
                </ul>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class="illustration-optimization"
                    src="@/assets/CompostPile/plot_optimization.png"
                ></v-img>
                <span>The dots represent runs used to optimize the pile shape. </span>
                <span>The surface is the result of fitting the data points with quintic extrapolation. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Detailed Dynamics of the Optimal Pile</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>Bacteria concentration, oxygen concentration, and temperature in the simulation grid are plotted below. </span>
                <span class="evolution-label">Bacteria Evolution</span>
                <v-img src="@/assets/CompostPile/ideal-pile_bacteria.png"></v-img>
                <span class="evolution-label">Oxygen Evolution</span>
                <v-img src="@/assets/CompostPile/ideal-pile_oxygen.png"></v-img>
                <span class="evolution-label">Temperature Evolution</span>
                <v-img src="@/assets/CompostPile/ideal-pile_temperature.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span> Below are plots of average bacteria concentration, remaining mass, average oxygen concentration, and average temperature as functions of time. </span>
            </v-col>
        </v-row><v-row class="wrapper">
            <v-col md='6' sm='12'>
                <v-img class='plot' src="@/assets/CompostPile/plot_bac.png"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class='plot' src="@/assets/CompostPile/plot_m.png"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class='plot' src="@/assets/CompostPile/plot_oxy.png"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img class='plot' src="@/assets/CompostPile/plot_temp.png"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Conclusion</span>
                <span>With the theory of the collapsing pile and the model of bacteria activity, we found the optimal size for a compost pile to be <b>0.7m by 0.7m by 0.4m</b> (L x W x H). This size is <b>easily manageable</b> in one’s backyard, which would be a great way to reduce household waste. Future simulations could take into consideration <b>different types of bacteria</b> and the <b>local consumption of matter</b>, and potentially use <b>more robust algorithms</b> with a finer grid to simulate time evolution.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>References and Attributions</span>
            </v-col>
        </v-row>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>References</span>
                <ul>
                    <li>Ginkel, J.T., Raats, P.A.C., and Van Haneghem, I.A. (1999) ‘Bulk density and porosity distributions in a compost pile’, <i>Netherlands Journal of Agricultural Science</i>, Vol. 47, pp. 105-121.</li>
                    <li>Lin, Y.P., Huang, H., Lu, H.W., and He, L. (2008) ‘Modeling of substrate degradation and oxygen consumption in waste composting processes’, <i>Waste Management</i> Vol. 28, Is. 8, pp. 1375-1385.</li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Attributions</span>
                <ul>
                    <li>Compost by Eucalyp from the Noun Project</li>
                    <li>bubbles by The Icon Z from the Noun Project</li>
                    <li>insulation by Stella Cohen from the Noun Project</li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"CompostPile",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.physicsEntries){
                console.log("find theme color", entry)
                if(entry.title=="Compost Pile"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>
<style lang="scss" scoped>
.illustration-equation{
    max-width: 250px;
}
.illustration-equation-bigger{
    max-width: 290px;
}
.illustration-equation-biggest{
    max-width: 350px;
}
.illustration-equation-small{
    max-width: 100px;
}
.evolution-label{
    margin-bottom: -50px;
    margin-left: 50px;
}
.illustration-icon{
    max-width: 100px;
}
.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(109, 76, 65, 0.3);
    }
}
.plot{
    margin: 20px;
}
</style>