<template>
    <div class='project-page'>
        <ProjectHero :src="'art/Translator/strips_compressed.jpg'"
        :sides="60"  :min-radius="0.6" :theme-color="themeColor">
            <template v-slot:title>
                Pocket Translator
            </template>
            <template v-slot:subtitle>
                Effortless Communication for Chinese International Students
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">After spending the past six years in the United States, I have picked up the habit of embedding English words and phrases in both spoken and typed Mandarin, mostly in communicating with friends. Some English words felt unique in what they could describe that no other word in Mandarin could convey the same meaning. Some subtle connotations. Some fun pronunciation. Some clever puns. Some English words describe concepts and stories I had simply not encountered or lived when I grew up in China. Especially words and concepts around school, my study, what I do in where I now live. Sometimes also words that form hidden stories that surfaced in therapy, which has always been in English. </span>
                <span class="project-statement-text">
                However, communicating with family and old friends back in China now requires a strange sense of effort. With my family, who don’t speak much English, I often need to carefully translate every word to my best knowledge when I call or text them. Sometimes I feel the genuine urge to communicate effectively and more comfortably for myself, so my mother now knows a handful of words: “lab”, “professor”, “project”, “computational design”, “research”, etc. With my old friends, I need to do the same to avoid being judged as “condescending”.  There is hostility towards international students who go back to China and could not unlearn this form of speech, especially in online forums. </span>
                <span class="project-statement-text">
                This speculative design project is a machine that translates a Chinese sentence that has mixed English words into two sentences, one in English and one in Chinese. With the aid of this machine, international students could speak freely in any combination of Chinese and English they wish and pass on the information to their audience effortlessly. </span>
                <span class="project-statement-text">
                Do we speak a third language? How does this new physical effort – tearing a piece of paper and passing it to the audience – compare with the old mental labor of translating worlds and culture? Maybe people will find the mental labor of translating to Mandarin a form of respect, care, and a reminder for them of their roots, and speaking full English shows successful adaptation and respect for local culture. However, I find that while roots don’t grow from nowhere, they absorb every change in soil into their organic whole. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Independent Project</span>
                <span>Time: July 2023</span>
                <span>Many thanks to two participants who played with this device. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Related Work</span>
                <span>The phenomenon of mixing languages in communication is often called code switching or code mixing, studied extensively in language learning environments and multilingual families. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Implementation</span>
                <span> The implementation of both detecting Chinese-English mixture and translating them uses OpenAI model API calls. The printer is a thermal receipt printed communicated via Python ESC/POS command library: python-escpos. </span>
            </v-col>
        </v-row>
        
        <v-row class="wrapper">
             <v-col md='12' sm='12'>
                <span class='section-subtitle'>Transcription and Mixture Detection</span>
                <span>Transcription with Whisper and non-ASCII character detection with simplified Regular Expression. Whisper is trained with multilingual data, and it performs well with transcribing mixed languages. However, detecting what language is in the transcription is hard, so I used the non-ASCII character detection that works decently with Chinese detection. </span>
             </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <!-- HTML generated using hilite.me --><div class="highlighted-code" style="background: #ffffff; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%">transcript <span style="color: #333333">=</span> openai<span style="color: #333333">.</span>Audio<span style="color: #333333">.</span>transcribe(<span style="background-color: #fff0f0">&quot;whisper-1&quot;</span>, audio_file)
transcript_text <span style="color: #333333">=</span> transcript[<span style="background-color: #fff0f0">&#39;text&#39;</span>]
<span style="color: #008800; font-weight: bold">if</span> re<span style="color: #333333">.</span>search(<span style="background-color: #fff0f0">&#39;[a-zA-Z]&#39;</span>, transcript_text) <span style="color: #000000; font-weight: bold">and</span> <span style="color: #007020">len</span>(re<span style="color: #333333">.</span>sub(<span style="background-color: #fff0f0">&#39;[ -~]&#39;</span>, <span style="background-color: #fff0f0">&#39;&#39;</span>, transcript_text))<span style="color: #333333">&gt;</span><span style="color: #0000DD; font-weight: bold">0</span>:
    <span style="color: #008800; font-weight: bold">print</span>(<span style="background-color: #fff0f0">&quot;&gt;&gt;&gt; mixture detected&quot;</span>)
    <span style="color: #007020">self</span><span style="color: #333333">.</span>result_deque<span style="color: #333333">.</span>append(transcript[<span style="background-color: #fff0f0">&#39;text&#39;</span>])
</pre></div>

            </v-col>
        </v-row>
        <v-row class="wrapper">
             <v-col md='12' sm='12'>
                <span class='section-subtitle'>Translation</span>
                <span>Translating mixed contents with GPT 3.5 Turbo, a conversational agent with custom prompts. </span>
             </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <!-- HTML generated using hilite.me --><div class="highlighted-code" style="background: #ffffff; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #008800; font-weight: bold">def</span> <span style="color: #0066BB; font-weight: bold">gpt_mixed_to_zh_en</span>(mixed_text):

    zh_response <span style="color: #333333">=</span> openai<span style="color: #333333">.</span>ChatCompletion<span style="color: #333333">.</span>create(
    model<span style="color: #333333">=</span><span style="background-color: #fff0f0">&quot;gpt-3.5-turbo&quot;</span>,
    messages<span style="color: #333333">=</span>[
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;system&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>:<span style="background-color: #fff0f0">&quot;在所有回答中遵循我的所有规则&quot;</span>},
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;system&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>:<span style="background-color: #fff0f0">&quot;除了专有名词，所有回答都应该是简体中文。&quot;</span>},
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;system&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>:<span style="background-color: #fff0f0">&quot;把常见人名也都翻译成中文。&quot;</span>},
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;user&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>: <span style="background-color: #fff0f0">&quot;翻译接下来的文本成中文：&#39;{}&#39;&quot;</span><span style="color: #333333">.</span>format(mixed_text)}
        ]
    )
    zh <span style="color: #333333">=</span> zh_response[<span style="background-color: #fff0f0">&#39;choices&#39;</span>][<span style="color: #0000DD; font-weight: bold">0</span>][<span style="background-color: #fff0f0">&#39;message&#39;</span>][<span style="background-color: #fff0f0">&#39;content&#39;</span>]

    en_response <span style="color: #333333">=</span> openai<span style="color: #333333">.</span>ChatCompletion<span style="color: #333333">.</span>create(
    model<span style="color: #333333">=</span><span style="background-color: #fff0f0">&quot;gpt-3.5-turbo&quot;</span>,
    messages<span style="color: #333333">=</span>[
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;system&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>:<span style="background-color: #fff0f0">&quot;Please follow all these rules in all your responses.&quot;</span>},
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;system&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>:<span style="background-color: #fff0f0">&quot;All answers should be in English. &quot;</span>},
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;system&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>:<span style="background-color: #fff0f0">&quot;Do not repeat the line in the prompt. &quot;</span>},
            {<span style="background-color: #fff0f0">&quot;role&quot;</span>: <span style="background-color: #fff0f0">&quot;user&quot;</span>, <span style="background-color: #fff0f0">&quot;content&quot;</span>: <span style="background-color: #fff0f0">&quot;Translate this line into English: &#39;{}&#39;&quot;</span><span style="color: #333333">.</span>format(mixed_text)}
        ]
    )
    en <span style="color: #333333">=</span> en_response[<span style="background-color: #fff0f0">&#39;choices&#39;</span>][<span style="color: #0000DD; font-weight: bold">0</span>][<span style="background-color: #fff0f0">&#39;message&#39;</span>][<span style="background-color: #fff0f0">&#39;content&#39;</span>]
    <span style="color: #008800; font-weight: bold">return</span> zh, en
</pre></div>

            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Experiments</span>
                <span>I tested the prototype with two participants in two sessions. They entered the session without knowing the purpose of this receipt printer.</span> 
            </v-col>
        </v-row>

        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>
                Participant A took about 5 minutes to realize that the printer only prints sentences that had mixed language. They continued to talk with me about their summer, while we casually observed how the printer responded to scattered sentences. They asked to keep the roll after the session. 
                </span>
            </v-col>
        </v-row>

        <v-row class="wrapper">
             <v-col md='3' sm='6'>
                <v-img
                    src="@/assets/art/Translator/pb-hand.jpg"
                ></v-img>
            </v-col>
            <v-col md='9' sm='6'>
                <span>Participant B did not find out how to use this machine after 20 minutes of talking, so I revealed the goal to them. I noticed that they did not speak with as much mixed language as Participant A, and when they did, it would be short words that Whisper automatically translated to Chinese, or more words with heavier accent that was hard to decipher for the model. </span>
            </v-col>
        </v-row>
        
        <v-row class="wrapper">
             <v-col md='3' sm='6'>
                <v-img
                    src="@/assets/art/Translator/tangible-things-2.jpg"
                ></v-img>
            </v-col>
            <v-col md='9' sm='6'>
                <span>The translation quality could be improved. However, it gave us a sense of delight and irony, when the model could not make sense of a sentence that made perfect sense to us. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Conclusion and Prospects</span>
                <span>I would like to develop this project further. For example, I would like to make the machine portable and live with it for a few days. I would like to have it record the sentences for future analysis. </span>
                <span>This project gives me a lingering awareness of my language whenever I speak in Mandarin since I don’t mix Chinese words into English as much as the other way around. Participant A and a friend noted that although I built this project, I have made no effort to change the way I talk. However, this awareness could be subtly changing it without my conscious decision. </span>
            </v-col>
        </v-row>
        

        <!-- <v-divider></v-divider>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References</span>
                <ol>
                    <li>Deborah Lupton. 2017. Feeling your data: Touch and making sense of personal digital data. New Media & Society 19, 10: 1599–1614. https://doi.org/10.1177/1461444817717515</li>
                    <li>Byung-Chul Han. 2018. Saving beauty. Polity Press, Cambridge, UK ; Malden, MA.</li>
                    <li>Donna Haraway. 2016. Staying with the Trouble: Making Kin in the Cthulucene. Experimental Futures: Technological Lives, Scientific Arts, Anthropological Voices. Duke University Press. Durham, NC. </li>
                    <li>Anna L. Tsing. 2015. The Mushroom at the End of the World. Princeton University Press. Princeton, NJ. </li>
                    <li>Michael Reddy. 1979. “The Conduit Metaphor: A Case of Frame Conflict in our Language about Language” in Ortony (ed.) Metaphor and Thought. Cambridge University Press. Cambridge, UK.</li>
                </ol>
            </v-col>
        </v-row> -->
    </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
//import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
import ProjectHero from '@/components/ProjectHero.vue'

export default {
    name:"Translator",
    components:{
        //AnimatedPolygon
        ProjectHero,
    },
    data: ()=>{
        return {
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.artEntries){
                console.log("find theme color", entry)
                if(entry.title=="Pocket Translator"){
                    return entry.titleBg
                }
            }
            return "#000000"
        },
        
    },
    mounted(){
        
    }
}
</script>
<style lang="scss" scoped>

.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(142, 36, 170, 0.3);
    }
}

div#bubble-space{
    position: relative;
    height: 500px;
    overflow: hidden;
}

div#bubble-space-demo{
    position: relative;
    height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    span{
        margin: 50px
    }
}

.illustration-icon{
    max-width: 150px;
    max-height: 150px;
    margin: auto;
}
.illustration{
    max-width: 250px;
    max-height: 250px;
    margin: auto;
}
.icon-text{
    display: inline-block;
}
.sam{
    max-width: 650px;
    margin: auto;
}

div.highlighted-code{
    span{
        display: initial;
    }
}

span.action-subtitle{
    margin-top: 15px;
}
</style>