<template>
    <div class='project-page'>
        <ProjectHero 
        :sides="60"  :min-radius="0.4" :theme-color="themeColor">
            <template v-slot:title>
                bubble space
            </template>
            <template v-slot:subtitle>
                A Fun Visual Tool for Mood Tracking
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <div id="bubble-space">
            <Bubble v-for="b in bubbles" :key='b.zid' :zid="b.zid" :active='introActive' 
            :top="b.top" :left="b.left" :p='b.p' :a='b.a' :d='b.d' />
        </div>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <span>The "bubble space" is stylized not to be capitalized to convey its tenderness and unobtrusiveness.</span>
        </v-row>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">Inspired by the fleeting nature of human emotions, bubble space is a space for self-reflection and mood tracking. As a web-based app, it allows people to document their emotions as they navigate through challenges in life. The input template is derived from cognitive behavioral therapy, which includes fields for situation, thoughts, and feelings. The emotion associated with an event is quantified using the pleasure-arousal-dominance model, so that the trend of emotions can be visualized through the size and the color of a “mood bubble” as well as a line chart. As I was developing this web app, I appreciated its visual way of displaying my emotional journey and its potential to signal that I need to take care of my emotional needs. I believe that bubble space could grow further to support intelligent analysis of emotions and provide insights to people regarding their mental health.
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Independent Project</span>
                <span>Time: May 2018 - Oct 2020</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Motivation and Research</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4' sm='12'>
                <v-img class="illustration-icon"
                    src="@/assets/bubble-space/noun_Track_3067686.png"
                ></v-img>
            </v-col>
            <v-col md='8' sm='12'>
                <span class='section-subtitle'>Why track Mood?</span>
                <span>Mood tracking addresses the mental health needs of both the general public and people with mental health conditions, increases their <b>awareness of their emotions</b>, and empowers them to <b>play an active role</b> in maintaining emotional health (Caldeira et al, 2017). </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='8' sm='12'>
                <span class='section-subtitle'>The Cognitive Triangle</span>
                <span>Mood tracking also provides insight into <b>thought and behavior patterns</b>, with the potential to modify them.</span>
                <span>According to the theory of cognitive behavioral therapy, thoughts, behaviors, and feelings are <b>interconnected</b> (Smart Talk, 2018). An event will trigger a thought, a thought an emotion, and an emotion motivates us to act accordingly. Similarly, emotions also have the power of generating thoughts and behaviors, for example, feeling down might lead to self-criticizing thoughts and avoidance behaviors.   </span>
                <span>By becoming aware of the emotions, the connection to thoughts and behaviors from the emotions are no longer automatic, which allows <b>room for intervention and progress</b>. </span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img
                    src="@/assets/bubble-space/cognitive_triangle.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='2'>
            </v-col>
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Why mood tracking with a web app?</span>
                <span>While paper-based mood tracking worksheets are available for free on many platforms, the now ubiquitous smartphones have more power to <b>visualize and analyze a large amount of mood data</b>. Instead of carrying a worksheet around and jotting down event entries, using a web app can be <b>inconspicuous and timesaving</b>. </span>
            </v-col>
            <v-col md='2'>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4'>
                <v-img src="@/assets/bubble-space/iphone_in_iphone-01.png"></v-img>
            </v-col>
            <v-col md='8' sm='12'>
                <v-img src="@/assets/bubble-space/paper.jpg"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>The PAD model</span>
                <span>While some apps use emojis and pictures to visualize mood and some apps use one number to rate the pleasure of an emotion, bubble space takes a more detailed, quantitative approach by using the <b>pleasure-arousal-dominance model</b>.</span>
                <span>Developed by Albert Mehrabian and James A. Russel in 1974, the PAD model is an emotional state model for all emotions based on <b>three numerical dimensions</b>, pleasure, arousal, and dominance (Wikipedia, 2020).</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>The Pleasure-Displeasure Scale measures how <b>pleasant or unpleasant</b> one feels. For example, joy is a pleasant emotion while sadness is unpleasant (Wikipedia, 2020).</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <v-img class='sam' src="@/assets/bubble-space/sam-pleasure.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>The Arousal-Nonarousal Scale measures how <b>energized or soporific</b> one feels. It is not the intensity of the emotion, for grief can be intense but it does not energize a person. Anger and boredom are both unpleasant, but anger is a higher arousal state while boredom is lower on the scale (Wikipedia, 2020). </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <v-img class='sam' src="@/assets/bubble-space/sam-arousal.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>The Dominance-Submissiveness Scale represents how <b>controlling and dominant versus controlled or submissive</b> one feels. For instance while both fear and anger are unpleasant emotions, anger is a dominant emotion, while fear is a submissive emotion (Wikipedia, 2020).</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <v-img class='sam' src="@/assets/bubble-space/sam-dominance.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>The three panels of human figures are the <b>Self-Assessment Manikin</b> (SAM) used to rate the dimensions of pleasure (top panel), arousal (middle panel), and dominance (bottom panel), developed by Bradley and Lang (1994). They can be used to familiarize the user with the PAD model to allow fast self-assessment. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Target Users</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='6' md='3'>
                <v-img class='illustration'  aspect-ratio="1.33"
                src="@/assets/bubble-space/collage_teens.png"></v-img>
            </v-col>
            <v-col sm='8' md='9'>
                <ul>
                    <li>Adolescents </li>
                    <li>Have access to smartphones and the internet.  </li>
                    <li>Can understand the PAD model and provide consistent ratings. </li>
                    <li>Are curious about their own emotion pattern.  </li>
                    <li>Are looking for fun visual experiences. </li>
                </ul>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Design</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4' sm='12'>
                <v-img src="@/assets/bubble-space/dialog.png"></v-img>
            </v-col>
            <v-col md='8' sm='12'>
                <span class='section-subtitle'>Bubble Input</span>
                <ul>
                    <li><b>Title</b>: Title of the bubble, used for display in a list</li>
                    <li><b>Date</b>: date of the record</li>
                    <li><b>Pleasure scale</b>: from -5 very unpleasant to 5 very pleasant</li>
                    <li><b>Arousal scale</b>: from -5 very low energy to 5 highly energized emotions</li>
                    <li><b>Dominance scale</b>: from -5 feeling submissive to 5 feeling controlling</li>
                    <li><b>Situation</b>: the prompting event</li>
                    <li><b>Thoughts</b>: thoughts around the event</li>
                    <li><b>Feelings</b>: some emotion words in additional to the 11-point scale</li>
                </ul>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Appearance of the Bubbles</span>
                <span><b>Color gradients</b> are mixed to provide a unique color for each emotion based on its <b>pleasure, arousal, and dominance scores</b>. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <v-img src="@/assets/bubble-space/maps_stack.svg"></v-img>
            </v-col>
            <v-col md='6' sm='12' class='text-center'>
                <v-img src="@/assets/bubble-space/background_code.png"></v-img>
                <span class="pt-3">Code for gradient mixture</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <b>The size of the bubble</b> is proportional to the <b>intensity of the emotion</b>, i.e. the sum of the absolute value of its pleasure, arousal, and dominance scores.
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='6'>
                <v-img src="@/assets/bubble-space/intense_no_chart.png"></v-img>
            </v-col>
            <v-col md='6' sm='6'>
                <v-img src="@/assets/bubble-space/milder_no_chart.png"></v-img>
                <span>left: bubbles associated with a week of <b>intense emotions</b>. </span>
                <span>top: bubbles associated with <b>milder emotions</b>.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Timeline</span>
                <span>The <b>temporal sequence of emotions</b> provides important information on a person’s emotional well being. Being in a very low state for more than two weeks is a symptom for depression (Truschel, 2020), and a line chart of consistent low pleasure and low arousal states is a direct visual cue for such depressive moods. 
                </span><span>
                Therefore, in the second iteration, I added the functionality to select the time range to display the bubbles as well as to plot the trends of three mood values in a line chart. 
                </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4' sm='4'>
                <v-img src="@/assets/bubble-space/date_picker_inside.png"></v-img>
            </v-col>
            <v-col md='8' sm='8'>
                <v-img src="@/assets/bubble-space/milder.png"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Interactive Animation</span>
                <span>To further enhance the interactive experience of bubble space, I added animation for the <b>entering and the leaving bubbles</b>.  </span>
            </v-col>
        </v-row>
        <v-row class="wrapper" >
            <v-col sm='12'>
                <div id="bubble-space-demo" @click="bubbleActive = !bubbleActive">
                    <span v-show="!bubbleActive">Click anywhere in the box to show bubbles</span>
                    <span v-show="bubbleActive">Click anywhere in the box to hide bubbles</span>
                    <Bubble v-for="b in demoBubbles" :key='b.zid' :zid="b.zid" :active='bubbleActive' 
                    :top="b.top" :left="b.left" :p='b.p' :a='b.a' :d='b.d' />
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Conclusion</span>
                <span>I designed the bubble space to convey the message that all emotions are <b>temporary</b> like bubbles, yet they contain snapshots of our <b>thinking patterns and emotional health</b>. By using color codes and varying sizes, I allow all emotions to appear on the stage, but nothing can emphasize more the fleeting nature of emotions than setting a timeframe for display. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>  
                <span>I also see the bubble space as a launching pad for <b>a more comprehensive aid</b> for mental health. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='8' sm='10'>  
                <span>For example, it could <b>alert users</b> if their mood has been low for weeks. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md="4" sm='2'>
                <v-img src="@/assets/bubble-space/wordcloud.png"></v-img>
            </v-col>
            <v-col md='8' sm='10'>  
                <span>To make bubble space more intelligent, <b>natural language processing techniques</b> could be used to analyze potential patterns between input thoughts and the associated emotions. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>  
                <span>To facilitate social support, as demonstrated in “Aurora” (Gay et all, 2011), bubble space can be a <b>collaborative space</b> where different people blow bubbles into the common area.  </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>  
                <span>The bubble space could host <b>mental health resources</b> and recommend them based on the mood trend.  </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References and Attributions</span>
            </v-col>
        </v-row>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>References</span>
                <ul>
                    <li>Bradley, M. and Lang, P. (1994) Measuring emotions: The self-assessment manikin and the semantic differential, <i>J. Behav. Ther. & Exp. Psychiat.</i>, Vol. 25, No. I. pp. 49-59.</li>
                    <li>Caldeira, C., Chen, Y., Chan., Pham, V., Chen, Y., Zheng, K. (2017) ‘Mobile apps for mood tracking: an analysis of features and user reviews’, <i>AMIA Annu Symp Proc</i>. 2017:p495–504. Available at https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5977660/ (Accessed: 24 October 2020)</li>
                    <li>Gay, G., Pollak, J., Adams, P., Leonard, J.P. (2011) Pilot study of Aurora, a social, mobile-phone-based emotion sharing and recording system. <i>J Diabetes Sci Technol.</i>, 5(2):325-32. Available at https://pubmed.ncbi.nlm.nih.gov/21527101/ (Accessed: 24 October 2020)</li>
                    <li>Smart Talk. (2018) <i>CBT – The Cognitive Triangle </i>[Online]. Available at https://smarttalktherapy.com/cbt-cognitive-triangle/ (Accessed: 24 October 2020)</li>
                    <li>Truschel, J. (2020) <i>Depression Definition and DSM-5 Diagnostic Criteria</i> [Online]. Available at https://www.psycom.net/depression-definition-dsm-5-diagnostic-criteria/#dsm-5diagnosticcriteria.</li>
                    <li>Wikipedia. (2020) <i>PAD emotional state model</i> [Online]. Available at https://en.wikipedia.org/wiki/PAD_emotional_state_model (Accessed: 24 October 2020)</li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Attributions</span>
                <ul>
                    <li>cognitive-triangle https://hudsontherapygroup.com/blog/cognitive-triangle</li>
                    <li>People vector created by freepik https://www.freepik.com/vectors/people</li>
                    <li>Track by The Icon Z from the Noun Project</li>
                    <li>down trending by Barracuda from the Noun Project</li>
                    <li>word cloud generated by https://www.wordclouds.com/</li>
                    <li>social media Woman photo created by rawpixel.com https://www.freepik.com/photos/woman</li>
                    <li>book School vector created by freepik https://www.freepik.com/vectors/school</li>
                    <li>Teen on phone marymarkevich @ freepik.com</li>
                    <li>student blackboard gpointstudio @ freepik.com</li>
                    <li>couple video games tirachardz</li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
//import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
import ProjectHero from '@/components/ProjectHero.vue'
import Bubble from '@/components/Bubble.vue'

export default {
    name:"BubbleSpace",
    components:{
        //AnimatedPolygon
        ProjectHero,
        Bubble
    },
    data: ()=>{
        return {
            introActive: false,
            bubbleActive: false,
            allBubbles:[
                {zid:1, top:10, left:10, p:2, a:2, d:4},
                {zid:2, top:310, left:300, p:2, a:1, d:0},
                {zid:3, top:220, left:80, p:-2, a:2, d:1},
                {zid:4, top:30, left:170, p:2, a:-2, d:3},
                {zid:5, top:20, left:220, p:2, a:2, d:-2},
                {zid:6, top:190, left:170, p:4, a:0, d:1},
                {zid:7, top:10, left:380, p:-3, a:-2, d:1},
                {zid:8, top:40, left:490, p:0, a:1, d:-1},
                {zid:9, top:10, left:510, p:-2, a:1, d:0},
                {zid:10, top:310, left:800, p:2, a:-1, d:-2},
                {zid:11, top:220, left:580, p:3, a:2, d:1},
                {zid:12, top:70, left:670, p:-4, a:-4, d:0},
                {zid:13, top:20, left:720, p:2, a:-2, d:-2},
                {zid:14, top:90, left:600, p:3, a:0, d:1},
                {zid:15, top:10, left:880, p:-3, a:4, d:-1},
                {zid:16, top:40, left:990, p:-3, a:2, d:-1},

                {zid:17, top:10, left:1510, p:2, a:-1, d:0},
                {zid:18, top:280, left:1300, p:3, a:1, d:-2},
                {zid:19, top:200, left:1080, p:-3, a:0, d:1},
                {zid:20, top:90, left:1170, p:-3, a:-4, d:-1},
                {zid:21, top:20, left:1220, p:1, a:-1, d:-2},
                {zid:22, top:90, left:1100, p:-2, a:0, d:1},
                {zid:23, top:10, left:1380, p:-3, a:-4, d:1},
                {zid:24, top:40, left:1490, p:-3, a:-1, d:-1}
            ]
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.compDesEntries){
                console.log("find theme color", entry)
                if(entry.title=="bubble space"){
                    return entry.titleBg
                }
            }
            return "#000000"
        },
        bubbles(){
            return this.allBubbles.filter((b)=>{
                if (parseInt(b.left)<window.innerWidth-30){
                    return b
                }
            })
        },
        demoBubbles(){
            return this.allBubbles.filter((b)=>{
                if (parseInt(b.left)<window.innerWidth-320){
                    return b
                }
            })
        }
    },
    mounted(){
        window.setTimeout(()=>{this.introActive=true}, 50)
    }
}
</script>
<style lang="scss" scoped>

.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(142, 36, 170, 0.3);
    }
}

div#bubble-space{
    position: relative;
    height: 500px;
    overflow: hidden;
}

div#bubble-space-demo{
    position: relative;
    height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    span{
        margin: 50px
    }
}

.illustration-icon{
    max-width: 150px;
    max-height: 150px;
    margin: auto;
}
.illustration{
    max-width: 250px;
    max-height: 250px;
    margin: auto;
}
.icon-text{
    display: inline-block;
}
.sam{
    max-width: 650px;
    margin: auto;
}
</style>