<template>
    <div class='project-page'>
        <ProjectHero :src="'PTCam/Mockup-2.png'"
        :sides="25"  :min-radius="0.4" :theme-color="themeColor">
            <template v-slot:title>
                PTCam
            </template>
            <template v-slot:subtitle>
                Enabling Self-Examination of Musculoskeletal Conditions Using a Low-Cost Camera
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">PTCam provides a novel way to assess shoulder health through machine learning. Unlike traditional clinic visits which may not be accessible due to financial barriers or the pandemic, users can receive possible diagnoses on shoulder conditions and suggestions for rehabilitation by answering a series of questions and performing designed tests in front of a webcam. As the group lead of the only two web developers, I was able to develop a usable product in 40 days with Vue.js and Flask. The web interface for PTCam makes it possible for the general population to enjoy the benefit of state-of-the-art algorithms without field-specific knowledge. I believe that more products like PTCam will join the ecosystem of telemedicine and lower the barrier to healthcare in many aspects. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: Yuan Liang, Bowen Wang, Jianlong Ye, <b>Yumeng Zhuang</b>, Eric Balagtas Perez, Liang Qiu, Caitlin Lee, Claire Guo, Kun Wang, Dapeng Wang, Kun Wang, Lei He, Xiang ‘Anthony’ Chen</span>
                <span>My Role: Web developer lead</span>
                <span>Time: August 2020 - September 2020</span>
                <!--<span>Subitted to CHI 2021</span><span>(May be) live at: <a target="_blank" href="https://pt-cam.site">pt-cam.site</a></span>-->
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <span class='section-title'>Background</span>
                <span>Musculoskeletal Conditions (MSK conditions)</span>
                <ul>
                <li>Are the <b>leading contributor</b> to disability worldwide (World Health Organization, 2019).</li>
                <li>Affect <b>a third</b> of the US population (Yelin, Weinstein, and King, 2016) and <b>a quarter</b> of the population in Europe (Woolf, Erwin, and March, 2012).</li>
                </ul>
            </v-col>
            <v-col md='3' sm='6'>
                <v-img class="illustration-icon"
                    src="@/assets/PTCam/Background-US.png"
                ></v-img>
            </v-col>
            <v-col  md='3' sm='6'>
                <v-img class="illustration-icon"
                    src="@/assets/PTCam/Background-EU.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4'>
                <v-img
                    height='160px'
                    id="man-with-pain"
                    src="@/assets/PTCam/man_with_pain.png"
                ></v-img>
            </v-col>
            <v-col md='8'>
                <span>Shoulder conditions are the focus of this project because they have</span>
                <ul>
                    <li>A high lifetime prevalence (Luime et all, 2004).</li>
                    <li>Potential to severely impact people’s ability to function in life if not treated properly (Cadogan et al, 2011). </li>
                </ul>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col class='d-none'>
            </v-col>
            <v-col md='12' sm='12'>
                <span class='section-title'>Problem</span>
                <span>As common as the MSK conditions are, it is not always easy to get treatment. </span>
                <ul>
                <li>Primary care visits: usually required <b>6 times</b> a year (Carvalho, Bettger, and Goode, 2018).</li>
                <li>Specialist visits: limited by <b>geographical</b> and <b>socioeconomical</b> factors (Goode, Freburger, and Carey, 2012).</li>
                <li>The physical therapy copay can be as high as <b>$75</b> per visit (Carvalho, Bettger, and Goode, 2018).</li>
                <li>Travel cost: telemedicine showed that an average journey of <b>145 miles</b> and <b>142 min</b> per visit could be saved (Russo, McCool, and Davies, 2016).</li>
                </ul>
            </v-col>
            <v-col class='d-none'>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4' class="text-center">
                <v-img  class="illustration-icon" aspect-ratio="1.2" src='@/assets/PTCam/icons [Recovered]_meeting.png'></v-img>
                <span class="icon-text">6 visits per year</span>
            </v-col>
            <v-col md='4' class="text-center">
                <v-img  class="illustration-icon" aspect-ratio="1.2" src='@/assets/PTCam/icons [Recovered]_money.png'></v-img>
                <span>$75 per visit</span>
            </v-col>
            <v-col md='4' class="text-center">
                <v-img  class="illustration-icon" aspect-ratio="1.2" src='@/assets/PTCam/icons [Recovered]_car.png'></v-img>
                <span>142 min travel</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Existing Solutions</span>
                <span>Wearable sensors (Jovanov et all, 2005), cameras with motion sensors (Roy, Soni, and Dubey, 2013), and mobile apps have been used to promote therapeutic exercises at home.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4' sm='12' class="text-center">
                <v-img class="illustration-icon" aspect-ratio="1" src="@/assets/PTCam/icons [Recovered]_wearable sensors.svg"></v-img>
            </v-col>
            <v-col md='4' sm='12' class="text-center">
                <v-img class="illustration-icon" aspect-ratio="1" src="@/assets/PTCam/icons [Recovered]_depth camera.svg"></v-img>
            </v-col>
            <v-col md='4' sm='12' class="text-center">
                <v-img class="illustration-icon" aspect-ratio="1" src="@/assets/PTCam/icons [Recovered]_app.svg"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='8' sm='12'>
                <span class='section-title'>Expert Interview</span>
                <span>We interviewed three physical therapists to inform us about what a typical visit looks like:</span>
                <span>Physical therapy visit walk-through</span>
                <ul>
                <li>Collect patient <b>background</b> and symptom descriptions</li>
                <li>Recommend <b>emergency</b> care if conditions are severe</li>
                <li><b>Examine</b> joints and muscles</li>
                <li>Instruct patient to perform certain <b>tests</b></li>
                <li>Give <b>results</b> based on range of motion and discomfort</li>
                </ul>
            </v-col>
            <v-col md='4' class="d-none d-md-flex">
                <v-img class="doctor-silhouette" src="@/assets/PTCam/interview-01.svg"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <!--
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Obstacles to Remote Physical Therapy And Our Solutions</span>
                <span> to the interviews we conducted, we identified three areas of obstacles to providing remote physical therapy. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='4' sm='12' class="text-center">
                <v-img class="illustration-icon" aspect-ratio="1"  src="@/assets/PTCam/obstacles-no internet.png"></v-img>
                <span class="icon-text">Poor internet connection.</span>
            </v-col>
            <v-col md='4' sm='12' class="text-center">
                <v-img class="illustration-icon" aspect-ratio="1" src="@/assets/PTCam/obstacles-bad image quality.png"></v-img>
                <span class="icon-text">Poor camera quality.</span>
            </v-col>
            <v-col md='4' sm='12' class="text-center">
                <v-img class="illustration-icon" aspect-ratio="1" src="@/assets/PTCam/obstacles-unclear instrucion.png"></v-img>
                <span class="icon-text">
                   Unclear instruction and lack of feedback. 
                </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm=12>
                Our Solutions
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12' class="d-flex">
                <v-img class="illustration-icon" aspect-ratio="1" 
                src="@/assets/PTCam/obstacles_upload.png"></v-img>
                <span class="pl-2">Upload recorded video frames to prevent lagging and loss of information</span>
            </v-col>
            <v-col md='6' sm='12' class="d-flex">
                <v-img class="illustration-icon" aspect-ratio="1" src="@/assets/PTCam/obstacles-multi-instruction.png"></v-img>
                <div class="pl-2">
                <span>Provide multiple forms of instructions: text-based and image-based, with multiple angles of view</span>
                <span>Overlay on image for user to validate the algorithm</span>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>-->
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>User Survey</span>
                <span> We conducted user survey among college students and a few of their family members. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper survey-figure-wrapper">
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_age.png"></v-img>
                <span class='pt-3'>Age</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_diagnosis.png"></v-img>
                <span class='pt-3'>Past Diagnosis</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_shoulder_discomfort.png"></v-img>
                <span class='pt-3'>Shoulder Discomfort</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_PT_ever.png"></v-img>
                <span class='pt-3'>Ever PT?</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_remote PT.png"></v-img>
                <span class='pt-3'>Remote PT?</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_reasons.png"></v-img>
                <span class='pt-3'>Reasons for Remote</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_initial_exam.png"></v-img>
                <span class='pt-3'>Initial Exam</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_health_education.png"></v-img>
                <span class='pt-3'>Health Education</span>
            </v-col>
            <v-col sm='12' md='4' class='text-center'>
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/test_page_prescription.png"></v-img>
                <span class='pt-3'>Prescription</span>
            </v-col>
        </v-row>
        <v-row class='wrapper survey-figure-wrapper'>
            <v-col sm='12'>
                <span>A majority of the respondents report shoulder discomfort and an interest in products similar to PTCam.</span> 
            </v-col>
            <v-col sm='12' md='6' class="pie-chart-1">
                <v-img src="@/assets/PTCam/survey-interest.png"></v-img>
            </v-col>
            <v-col sm='12' md='6' class="pie-chart-2">
                <v-img src="@/assets/PTCam/survey-shoulder-discomfort.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>Respondents also gave reasons to consider remote physical therapy: </span>
                <ul>
                <li>77% of the respondents believed that remote physical therapy allows them to access care <b>without traveling long distances</b>. </li>
                <li>74% of the respondents believed that remote physical therapy <b>saves time</b>. </li>
                <li>56% of the respondents believed that remote physical therapy allows them to access care <b>if their mobility is restricted</b>. </li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="wrapper survey-figure-wrapper">
            <v-col sm='12' >
                <v-img class='main-survey-figure' contain
                src="@/assets/PTCam/survey-reasons.png"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>User Groups</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-subtitle'>Active College Students</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='6' md='3'>
                <v-img class='illustration-icon'  aspect-ratio="1.2" 
                src="@/assets/PTCam/refuses-pt.png"></v-img>
            </v-col>
            
            <v-col sm='6' md='3'>
                <v-img class='illustration-icon'  aspect-ratio="1.2" 
                src="@/assets/PTCam/swimmer-flex.png"></v-img>
            </v-col>
            <v-col sm='6' md='3'>
                <v-img class='illustration-icon' aspect-ratio="1.2" 
                src="@/assets/PTCam/googling-laptop.png"></v-img>
            </v-col>
            <v-col sm='6' md='3'>
            </v-col>
            <v-col sm='6' md='3'>
            </v-col>
            <v-col sm='8' md='9'>
                <ul>
                    <li>May sustain injuries from overhead sports</li>
                    <li>Might not want to see a physical therapist in person</li>
                    <li>More familiar with technology and thus prefer to solve problems themselves</li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-subtitle'>Older Adults</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='6' md='3'>
                <v-img class='illustration-icon' contain aspect-ratio="1.2" 
                src="@/assets/PTCam/old-person-silhouette.png"></v-img>
            </v-col>
            <v-col sm='6' md='3'>
                <v-img class='illustration-icon' aspect-ratio="1.2" 
                src="@/assets/PTCam/grocery-lifting.png"></v-img>
            </v-col>
            <v-col sm='6' md='3'>
                <v-img class='illustration-icon' aspect-ratio="1.2" 
                src="@/assets/PTCam/Magnifying_glass.png"></v-img>
            </v-col>
            <v-col sm='6' md='3'>
            </v-col>
            <v-col sm='6' md='3'>
            </v-col>
            <v-col sm='6' md='9'>
                <ul>
                    <li>Injury due to wearing of joints</li>
                    <li>Less familiar with technology</li>
                    <li>Hard for them to understand complex insurance terms and coverage plans 
                    </li>
                </ul>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Front-end Logic Design</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <v-img src='@/assets/PTCam/icons [Recovered]_flowchart combined.svg'></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Back-End Algorithms</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <v-img id="model-img" src="@/assets/PTCam/supp_model-01.png"></v-img>
                <ul class="pt-4">
                <li>An input photo of the user is <b>rescaled</b> to 512×512 with its color normalized (a). Then, it is fed into three cascaded sub-networks. </li>
                <li>First, a <b>UNet-structured convolutional neural network (CNN)</b> (b) segments the image into 2D semantic segmentation of 12 body parts. </li>
                <li>Second, a <b>regression CNN</b> (c) estimates: (i)3D rotations of 23 joints relative to their parent joint, and (ii)3D shapes of the joints. </li>
                <li>Third, a <b>transform CNN</b> (d) deforms a parametric body template given the estimated joints’ rotations and shapes for a predictive 3D body reconstruction (Omran et all, 2018). </li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='6'>
                <v-row>
                    <v-img src="@/assets/PTCam/measure_seg.png"></v-img>
                </v-row>
                <v-row>
                    <v-col cols='8'>
                    <v-img src="@/assets/PTCam/measure_parameter.png"></v-img>
                    </v-col>
                    <v-col cols='4'>
                    <v-img src="@/assets/PTCam/measure_mesh.png"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm='6'>
                <ul>
                <li><b>Segmentation-Based Measurement</b> measures the angular displacement from the 2D body segmentation by interpolating the segment of the moving body part on each frame. </li>
                <li><b>Parameter-Based</b>: estimates rotation parameters of the joints involved in the movement and sums all the joints contributing to the movement.</li>
                <li><b>Mesh-based</b>: samples mesh vertices of a body part, and measures its rotations by interpolating the 3D coordinates of the vertices.</li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='6'>
                <v-img src="@/assets/PTCam/video_regenerate.gif"></v-img>
                <span class='pt-1'>The subject performing arm forward flexion. </span>
            </v-col>
            <v-col sm='6'>
                <v-img src="@/assets/PTCam/seg_regenerate.gif"></v-img>
                <span class='pt-1'>The segmentation-based result of the clip. The angle is measured between the arm and the legs. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>UI Design Process</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12' md='6'>
                <v-img class="wireframe" src="@/assets/PTCam/test_page_sketch.png"></v-img>
            </v-col>
            <v-col sm='12' md='6'>
                <span class='section-subtitle'>Test Recorder Design</span> 
                <ul>
                <li>Before recording, the user clicks a button to turn on their webcam and another button that starts a five-second countdown. A rectangular box guides the user to stand up, step back and position their body to fit within the box as closely as possible. We use this box to crop the video frames for inference.</li>
                <li>When the countdown hits zero, the system starts recording for seven seconds, during which a user performs the given test; the recording can also be stopped by clicking the ‘stop’ button on the screen. </li>
                <li>In terms of recording any discomfort, users can play back the video and indicate periods of movement by labeling (simply clicking the video frame) the starting and ending frames wherein they feel discomfort.</li>
                </ul>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12' md='6'>
                <span class='section-subtitle'>Wireframes</span> 
            </v-col>
        </v-row>
        <v-row class='wrapper'>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <v-img src="@/assets/PTCam/test_page_intro page.png" class="wireframe"></v-img>
                        <span class="pt-1">Introduction: What PTCam is.</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <v-img src="@/assets/PTCam/test_page_questionnaire page copy.png" class="wireframe"></v-img>
                        <span class="pt-1">Red flag questions as the screening step. </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <v-img src="@/assets/PTCam/test_page_questionnaire page.png" class="wireframe"></v-img>
                        <span class="pt-1">Questionnaires to fill out before tests. </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <v-img src="@/assets/PTCam/test_page_test page.png" class="wireframe"></v-img>
                        <span class="pt-1">Test page: User views instructions and performs action. </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <v-img src="@/assets/PTCam/test_page_test result.png" class="wireframe"></v-img>
                        <span class="pt-1">Feedback for individual tests. </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <v-img src="@/assets/PTCam/test_page_final result.png" class="wireframe"></v-img>
                        <span class="pt-1">The final results from PTCam.</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12' md='6'>
                <span class='section-subtitle'>Website Interface Design</span> 
            </v-col>
        </v-row>
        <v-row class='wrapper'>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <span>Introduction</span>
                        <v-img src="@/assets/PTCam/macbook_intro.png"
                        aspect-ratio="1.5"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <span>Red Flag Questions</span>
                        <v-img src="@/assets/PTCam/macbook_questionnaire.png"
                        aspect-ratio="1.5"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <span>Background Questions</span>
                        <v-img src="@/assets/PTCam/macbook_background.png"
                        aspect-ratio="1.5"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <span>Testing Page</span>
                        <v-img src="@/assets/PTCam/macbook_test.png"
                        aspect-ratio="1.5"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <span>Test Result Page</span>
                        <v-img src="@/assets/PTCam/macbook_feedback.png"
                        aspect-ratio="1.5"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md='6' sm='12'>
                <v-row>
                    <v-col cols='12' class="text-center">
                        <span>Final Result</span>
                        <v-img src="@/assets/PTCam/macbook_final_result.png"
                        aspect-ratio="1.5"></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>User and Expert Evaluation</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>We conducted a deployment study with 10 participants with reported shoulder problems and two experienced physical therapists.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12' md='12'>
                <span>The user first interacted with PTCam <b>independently</b> under the supervision of a physical therapist, then filled out a questionnaire on the usability of the six key steps. The scores are included in the following table. </span>
                <span>Users raised some concerns and provided possible solutions.</span>
                <ul>
                    <li>Some symptoms <b>cannot be matched</b> to any item on the list.</li>
                    <li><b>Stepping backwards</b> every time to perform a movement was tiring; space might be a requirement for PTCam.</li>
                    <li>It was hard to <b>label accurate pain</b> periods in playback since it moved fast and a yes/no button could be better. </li>
                    <li>More detailed <b>information for the recommendation</b> page would be helpful, e.g., animation of recommended exercises.</li>
                </ul>
            </v-col>
            <v-col sm='12' md='12'>
                <v-img class="table-img" src="@/assets/PTCam/usability_study.svg"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12' md='12'>
                <span>The results from PTCam compared with clinical diagnoses from the two therapists are summarized in the table below. </span>
                <span>PTCam successfully detected <b>at least one pathology</b> for all the participants: it reached <b>exact same results</b> as the experts for six participants, while having <b>missed twice</b>. There was also an out-of-scope condition missed for three times: frozen shoulder, because there were other conditions detected. Overall, the results indicate the <b>clinical validity</b> of PTCam for enabling the self-examination of common shoulder conditions as early detection and screening. </span>
            </v-col>
            <v-col sm='12' md='12'>
                <v-img  class="table-img"  src="@/assets/PTCam/diagnosis.png"></v-img>
                <span>Green font indicates exact matches between PTCam results and physical therapist diagnosis. Red font indicates missed diagnosis and blue font indicates false positives. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Prospects: App</span>
                <span>To address the problem of having limited space and also bridge the gap between self-assessment and professional care, PTCam can be extended to include a phone application. </span>
                <v-img src = "@/assets/PTCam/web-iphone-flow_landscape.png"></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Limitations and Future Work</span>
                <span>Apart from adding a phone app for PTCam, there are other future directions.</span>
                <ul>
                    <li>PTCam cannot replace physical therapy visits when <b>touching and feeling injured areas</b> are necessary or when <b>professional assistance</b> for certain movements is required. <b>Actuation mechanisms with pressure sensors</b> could solve the problems at the expense of accessibility, but without those structures, PTCam could still serve as a screening platform prior to those necessary office visits. </li>
                    <li>The accuracy of the measurements could be improved with an <b>RGB-Depth camera</b>, but it would also limit the population that could benefit from PTCam with zero hardware components. </li>
                    <li>The model could also be more robust when <b>only part of the body</b> is visible in the camera, <b>by training different models</b> on datasets of a specific body segment and on physical therapy exercises.</li>
                    <li><b>Measurement could be made in real time</b>. Limited by the bandwidth and the computing power required to run our model, we did not choose to measure angles in real time. However, looking into solving these problems could save time for the user and enhance the interaction. </li>
                    <li>Although PTCam is only validated for the detection of three shoulder conditions, the design is not specific to shoulder conditions and is <b>extensible to other body parts</b>. With appropriate background questions, condition filtering criteria, tests, measurement guidelines, and tests evaluation rules, physical therapists could be able to extend the scope for PTCam based on their own expertise, so that PTCam could grow into a comprehensive tool for the general public.</li>
                    </ul>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References and Attributions</span>
            </v-col>
        </v-row>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>References</span>
                <ul>
                    <li>Cadogan, A., Leslett, M., Hing, W.A., McNair, P.J., Coates, M.H. (2011) A prospective study of shoulder pain in primary care: Prevalence of imaged pathology and response to guided diagnostic blocks. <i>BMC musculoskeletal disorders</i>, 12(1), p119.</li>
                    <li>Carvalho, E., Bettger, J.P., Goode, A.P. (2018) ‘Insurance Coverage, Costs, and Barriers to Care for Outpatient Musculoskeletal Therapy and Rehabilitation Services’, <i>N C Med J. Author manuscript</i>, 78(5): p312–314.</li>
                    <li>Goode, A.P., Freburger, J.K., Carey, T.S. (2012) ‘The influence of rural versus urban residence on utilization and receipt of care for chronic low back pain’, <i>J Rural Health</i>, 29(2):205-14.</li>
                    <li>Jovanov, E., Milenkovic, A., Otto, C., De Groen, P.C. (2005) ‘A wireless body area network of intelligent motion sensors for computer assisted physical rehabilitation’, <i>Journal of Neuroengineering and Rehabilitation</i>, 2(1), p1-10. </li>
                    <li>Luime, J.J., Kows, B.W., Hendriksen, I.J.M., Burdorf, A., Verhagen, A.P., Miedema, H.S., and Verhaar, J.A.N. (2004) ‘Prevalence and incidence of shoulder pain in the general population; a systematic review’, <i>Scandinavian journal of rheumatology</i> 33(2), p73-81.</li>
                    <li>Omran, M., Lassner, C., Pons-Moll, G., Gehler, P., and Schiele, B. (2018) ’Neural body fitting: Unifying deep learning andmodel based human pose and shape estimation’. In 2018 international conference on 3D vision (3DV). <i>IEEE</i>, p.484–494.</li>
                    <li>Roy, A.K., Soni, Y., Dubey, S. (2013) ‘Enhancing effectiveness of motor rehabilitation using kinect motion sensing technology’, In 2013 IEEE Global Humanitarian Technology Conference: South Asia Satellite (GHTC-SAS), <i>IEEE</i>, p298–304.</li>
                    <li>Russo, J.E., McCool, R.R., Davies L. (2016) ’VA Telemedicine: An Analysis of Cost and Time Savings’,  <i>Telemedicine and e-Health</i>, 22(3).</li>
                    <li>Wikipedia. (2020) <i>Kinect</i> [Online]. Available at https://en.wikipedia.org/wiki/Kinect. (Accessed: 24 October 2020)</li>
                    <li>Woolf, A.D., Erwin, J., March, L. (2012) ‘The need to address the burden of musculoskeletal conditions’ <i>Best Practice & Research Clinical rheumatology</i>, 26(2), p183-224.</li>
                    <li>World Health Organization. (2019) <i>Musculoskeletal conditions</i> [Online]. Available at https://www.who.int/news-room/fact-sheets/detail/musculoskeletal-conditions (Accessed: 24 October 2020)</li>
                    <li>Yelin, E., Weinstein, S., and King, T. (2016) ‘The burden of musculoskeletal diseases in the United States’, <i>Seminars in Arthritis and Rheumatism</i> 46(3), p 259-260. Available at https://www.sciencedirect.com/science/article/pii/S0049017216301445?via%3Dihub (Accessed: 24 October 2020)</li>
                </ul>
            </v-col>
           
        </v-row> 
        <v-row class="wrapper reference" >
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Attributions</span>
                <ul>
                    <li>band.jpg https://www.betterbraces.com/you-injured-your-rotator-cuff-now-what</li>
                    <li>demo-rotator-cuff.png https://www.youtube.com/watch?v=fhg7meEvvQM</li>
                    <li>man_with_pain https://liu.se/en/research/better-management-of-musculoskeletal-conditions</li>
                    <li>flag of US https://en.wikipedia.org/wiki/File:Flag_of_the_United_States.svg</li>
                    <li>flag of Europe https://commons.wikimedia.org/wiki/File:Flag_of_Europe.svg</li>
                    <li>swimmer-flex https://www.trainingcor.com/10-swimming-shoulder-pain-mistakes/</li>
                    <li>stop hand https://www.gograph.com/vector-clip-art/stop-hand-silhouette_3.html</li>
                    <li>google-laptop https://nypost.com/2018/12/05/googles-algorithm-creates-distinct-political-bubbles-study/</li>
                    <li>old-person https://www.clipartkey.com/view/mixJiT_old-person-silhouette-png/</li>
                    <li>grocery-lifting https://www.igoremmerich.com/blog/2012-08-06-everyday-olympics-weightlifting</li>
                    <li>magnifying glass https://en.wikipedia.org/wiki/Magnifying_glass</li>
                    <li>doctor-sil-male https://www.healthgrades.com/providers/francisco-jimeno-22r4k</li>
                    <li>shoulder bones etc https://www.verywellhealth.com/shoulder-anatomy-2549240</li>
                    <li>more by Kuber from the Noun Project</li>
                    <li>profile by Gregor Cresnar from the Noun Project</li>
                    <li>knee by Andrejs Kirma from the Noun Project</li>
                    <li>anatomy https://www.vectorstock.com/royalty-free-vector/cartoon-color-human-body-anatomy-set-vector-27280733</li>
                    <li>Info by Gregor Cresnar from the Noun Project</li>
                    <li>Share by Gregor Cresnar from the Noun Project</li>
                    <li>Doctor by ArtWorkLeaf from the Noun Project</li>
                    <li>Check by Kirsh from the Noun Project</li>
                    <li>PT-portrait https://durangoherald.com/articles/318164</li>
                    <li>call by pejyt from the Noun Project</li>
                    <li>Video by i cons from the Noun Project</li>
                    <li>Hang Up by Lyhn from the Noun Project</li>
                    <li>Video Off by Michiel Willemsen from the Noun Project</li>
                    <li>Switch Camera by Oksana Latysheva from the Noun Project</li>
                    <li>Network by Komkrit Noenpoempisut from the Noun Project</li>
                    <li>iphone mockup flat https://www.freepik.com/vectors/background’</li>
                    <li>Shoulder joint by Vectors Market from the Noun Project</li>
                    <li>neck by Deemak Daksina from the Noun Project</li>
                    <li>Laptop mockups https://www.anthonyboyd.graphics/?utm_source=file_shortcuts&utm_medium=downloaded_files</li>
                    <li>macbook mockup wrfrm kit</li>
                    <li>app contents civan design</li>
                    <li>reaching for phone Business photo created by master1305 - www.freepik.com</li>
                    <li>confused man cookie studio @ freepik.com</li>
                    <li>boxes freepik.com</li>
                    <li>blurred camera freepik.com</li>
                    <li>group yoga yanalya @freepik.com</li>
                    <li>macbook Mockup psd created by yeven_popov - www.freepik.com</li>
                    <li>iphone ipad rendering by Anthony Boyd</li>
                    <li>Button Click by Trevor Dsouza from the Noun Project</li>
                    <li>video recording by romzicon from the Noun Project</li>
                    <li>Video by Shital Patel from the Noun Project (flag)</li>
                    <li>physical by Eucalyp from the Noun Project</li>
                    <li>interview by Justin Blake from the Noun Project</li>
                </ul>
            </v-col>
        </v-row>
        
    </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"PTCam",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.interactionEntries){
                console.log("find theme color", entry)
                if(entry.title=="PTCam"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>
<style lang="scss">
#ptcam-hero-image{
    height: 100vh;
}
#man-with-pain .v-image__image{
    background-position: bottom !important
}

.survey-figure-wrapper{
    .main-survey-figure{
        //max-width: 650px;
        margin:auto;
        border: 1px solid #aaaaaa;
    }
    .pie-chart-1{
        max-width: 200px;
        margin:auto;
        border: 1px solid #aaaaaa;
    }
    .pie-chart-2{
        max-width: 200px;
        margin:auto;
        border: 1px solid #aaaaaa;
    }
}

.table-img{
    max-width: 650px;
    margin: auto;
}

.wireframe{
    border: 1px solid #888888;
}

.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(80, 77, 229, 0.3);
    }
}
.doctor-silhouette{
    //max-width: 90px;
    margin: 20px
}
.illustration-icon{
    max-width: 150px;
    max-height: 150px;
    margin: auto;
}
.icon-text{
    display: inline-block;
}
#model-img{
    max-width: 650px;
    margin:auto;
}
</style>