import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import ProjectPage from "../views/ProjectPage.vue"

//-----HCI
import ReconfigMM from '../views/ReconfigMM.vue' 
import WallInspection from '../views/WallInspection.vue' 
import PTCam from '../views/PTCam.vue'
import EchoMask from '../views/EchoMask.vue'
import Welding from '../views/Welding.vue'

//-----design with computation
import Mushroom from '../views/Mushroom.vue'
import NoiseDecoder from '../views/NoiseDecoder.vue'
import BubbleSpace from '../views/BubbleSpace.vue'

//----physics
import CPT from '../views/CPT.vue'
import CompostPile from '../views/CompostPile.vue'
import EinzelLens from '../views/EinzelLens.vue'
//import GardenCat from '../views/GardenCat.vue'

//-----art design
import WomanButton from '../views/WomanButton.vue'
import PryMe from '../views/PryMe.vue'
import Eyeballs from '../views/Eyeballs.vue'
import Translator from '../views/Translator.vue'
//import CatsCradle from '../views/CatsCradle.vue'
import Mallard from '../views/Mallard.vue'
//import AnimalsKitchen from '../views/AnimalsKitchen.vue'
//import MiscPaintings from '../views/MiscPaintings.vue'

import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'ProjectPage',
    component: ProjectPage,
    meta: { title: 'Projects' }
    
  },
  {
    path: '/PTCam',
    name: 'PTCam',
    component: PTCam,
    meta: { title: 'PT Cam' }
  },
  {
    path: '/bubble-space',
    name: 'BubbleSpace',
    component: BubbleSpace,
    meta: { title: 'Bubble Space' }
  },
  {
    path: '/CompostPile',
    name: 'CompostPile',
    component: CompostPile,
    meta: { title: 'Compost Pile' }
  },
  {
    path: '/NoiseDecoder',
    name: 'NoiseDecoder',
    component: NoiseDecoder,
    meta: { title: 'Noise Decoder' }
  },
  {
    path: '/EinzelLens',
    name: 'EinzelLens',
    component: EinzelLens,
    meta: { title: 'Einzel Lens' }
  },
  {
    path: '/CPT',
    name: 'CPT',
    component: CPT,
    meta: { title: 'CPT' }
  },
  /*{
    path: '/CKGT',
    name: 'CKGT',
    component: CKGT
  },*/
  {
    path: '/Mallard',
    name: 'Mallard',
    component: Mallard,
    meta: { title: 'Mallard' }
  },
  // {
  //   path: '/CatsCradle',
  //   name: 'CatsCradle',
  //   component: CatsCradle
  // },
  // {
  //   path: '/AnimalsKitchen',
  //   name: 'AnimalsKitchen',
  //   component: AnimalsKitchen
  // },
  // {
  //   path: '/MiscPaintings',
  //   name: 'MiscPaintings',
  //   component: MiscPaintings
  // },
  {
    path: '/Mushroom',
    name: 'Mushroom',
    component: Mushroom,
    meta: { title: 'Mushroom' } // short tile for document tab display
  },
  {
    path: '/Eyeballs',
    name: 'Eyeballs',
    component: Eyeballs,
    meta: { title: 'Eyeball Cape' }
  },
  {
    path: '/ReconfigMM',
    name: 'ReconfigMM',
    component: ReconfigMM,
    meta: { title: 'Reconfig MM' }
  },
  {
    path: '/WallInspection',
    name: 'WallInspection',
    component: WallInspection,
    meta: { title: 'Wall Inspection' }
  },
  {
    path: '/EchoMask',
    name: 'EchoMask',
    component: EchoMask,
    meta: { title: 'Echo Mask' }
  },
  {
    path: '/Welding',
    name: 'Welding',
    component: Welding,
    meta: { title: 'Intelligent Welding' }
  },
  {
    path: '/WomanButton',
    name: 'WomanButton',
    component: WomanButton,
    meta: { title: 'Woman Button' }
  },
  {
    path: '/PryMe',
    name: 'PryMe',
    component: PryMe,
    meta: { title: 'Pry Me Open' }
  },
  {
    path: '/Translator',
    name: 'Translator',
    component: Translator,
    meta: { title: 'Pocket Translator' }
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact' }
  },
  {
    path: '*',
    name: "NotFound",
    component: NotFound,
    meta: { title: 'Not Found' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log("scroll behavior")
    return { x: 0, y: 0 };
  }
})

export default router
