<template>
    <div class='project-page'>
        <ProjectHero
        :sides="15"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                Pry Me Open
            </template>
            <template v-slot:subtitle>
                May Touch Dominate Sight
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class='wrapper'>
            <v-col>
                <v-img src="@/assets/art/PryMe-Arm-tuned.png" class='painting-img' contain></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Statement</span>
                <span class="project-statement-text">It is believed that we often rely on Sight as our most dominant sense. Its exaltation above the other senses also stem from sight being viewed as the most "hygienic" or "non-invasive" social sense, detached from the bodily experiences other senses evoke, most notably, touch.
                </span>
                <span class="project-statement-text">
                However, our project argues that this over-exaltation of Sight ignores the way it can suppress sensuous curiosity or the potential for pleasure evoked by the other senses. Sight can also be pervasive – the gaze can be weaponized as a common method of invasion, aggression, or disregard of others. This case can be found in patriarchy and narcissism, and is critically reflected upon in Pleasure Activism.</span>
                <span class="project-statement-text">
                Our design makes the proactive vision process into a passive, vulnerable procedure. We turned sight into an unhygienic sense triggered by touch. The common and outward action turns into one thing that needs to be explored, as people may experience feelings of the unknown and a curious fear in this process.
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: <b>Yumeng Zhuang</b>, Olivia Luk, Shannon Lee</span>
                <span>My role: making the latex model, taking documentation photos, writing project documentaion collaboratively.</span>
                <span>September 2022, as Project 01 for <b>Possibistic Design</b> taught by Sinan Goral at Carnegie Mellon University.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Form</span>
            </v-col>
            <v-col md="4" sm='12'>
                <v-img src="@/assets/art/PryMe-White.jpg"></v-img>
            </v-col>
            <v-col md='8' sm='12'>  
                <span>The physical form of our design is a giant eyelid/slit that alludes to the eye. It is made of painted latex to look and feel like skin. It invokes the association of ideas about other parts of the body (vagina, scar, mouth) as well which evokes a visceral response in the body. </span>
                <span>To interact with the installation, users may feel compelled to pry the slit open. This invasive entrance to our anthropomorphic frame, forces users to confront the power dynamics between sight and touch.</span>
                <span>Inside the frame are various objects (pom-poms, ribbons, styrofoam, marbles, and gears) with different textures to evoke and stimulate the user’s sense of touch. All the items are either transparent or white to reduce the user’s reliance on Sight as a sense to explore. </span>
                <span>This space is infinite, allowing users to fully indulge in their tactile curiosity. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Process</span>
            </v-col>
            <v-col md='4' sm='6' class='text-center'>
                <v-img class="process-first3"
                src="@/assets/art/PryMe/paper_clay_mold.jpg" cover></v-img>
                <span class="illustration-text-topm">The base form is constructed with paper balls. </span>
            </v-col>
            <v-col md='4' sm='6' class='text-center'>
                <v-img class="process-first3"
                src="@/assets/art/PryMe/plastic_wrap_mold.jpg" cover></v-img>
                <span class="illustration-text-topm">The base form is then covered with non-dry clay and wrapped with plastic wrap. </span>
            </v-col>
            <v-col md='4' sm='6' class='text-center'>
                <v-img class="process-first3"
                src="@/assets/art/PryMe/wet_latex.jpg" cover></v-img>
                <span class="illustration-text-topm">The skin texture is modeled by layered paper towels soaked in liquid latex.</span>
            </v-col>
            <v-col md='4' sm='6' class='text-center'>
                <v-img class="process-last3"
                src="@/assets/art/PryMe/painted_cut.jpg" cover></v-img>
                <span class="illustration-text-topm">Then I painted the skin color with acrylic paint.</span>
            </v-col>
            <v-col md='4' sm='6' class='text-center'>
                <v-img class="process-last3"
                src="@/assets/art/PryMe/inside_stuff.jpg" cover></v-img>
                <span class="illustration-text-topm">We collected various white/transparent objects to stuff the structure.</span>
            </v-col>
            <v-col md='4' sm='6' class='text-center'>
                <v-img class="process-last3"
                src="@/assets/art/PryMe/taped_to_board.jpg" cover></v-img>
                <span class="illustration-text-topm">We taped the skin model to a base chip board.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Final Thoughts</span>
                <span>This project’s exploration into the power dynamics of sight vs. other senses intends to criticize the hegemony and dominance of sight over other senses (touch specifically). It was a thought provoking project that led to many discussions among three of us into the intentionality of creating and to reevaluate our experience of our world through each of our senses equally.</span>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"PryMe",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.artEntries){
                console.log("find theme color", entry)
                if(entry.title=="Pry Me Open"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>

<style lang="scss" scoped>

.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(142, 36, 170, 0.3);
    }
}

.process-first3{
    width: 300px;
    height: 200px;
    margin: auto;
}

.process-last3{
    width: 300px;
    height: 300px;
    margin: auto;
}

.illustration-text-topm{
    margin-top: 20px;
}
.icon-text{
    display: inline-block;
}

</style>