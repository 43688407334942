<template>
    <div class='project-page'>
        <ProjectHero :src="'EinzelLens/einzel_CAD.png'"
        :sides="7"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                Einzel Lens Optimization
            </template>
            <template v-slot:subtitle>
                In Electron Diagnostics for High Brightness Emission Cathodes
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">I contributed to the paper, Electron Diagnostics for Extreme High Brightness Nano-Blade Field Emission Cathodes, the discussion of possible improvement of the einzel lens used to focus the electron beam prior to its entrance to the hemispherical spectrometer. Einzel lens is an electrostatic element consist of three cylindrical co-linear electrodes, and it can focus an electron beam without changing its energy. However, it cannot focus electrons perfectly due to spherical aberration, and my role was to analyze the effect of different electrode shapes on reducing spherical aberration. Since the einzel lens field cannot be solved analytically, I used IBSimu, a C++ library for ion beam simulation, to compare different geometries. The results showed that an asymmetrical einzel lens gave the tightest focus. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team:  Gerard Lawler, Kunal Sanwalka, <b>Yumeng Zhuang</b>, Victor Yu, Timo Paschen, River Robles, Oliver Williams, Yusuke Sakai, Brian Naranjo, and James Rosenzweig. </span>
                <span>My Role: Analysis of the effect of einzel lens geometries on spherical aberration. </span>
                <span>Time:  August 2018</span>
                <span>Published on <a target="_blank" href="https://www.mdpi.com/2410-390X/3/4/57">Instruments 2019, 3(4), 57</a>.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Problem</span>
                <span><b>Einzel lenses</b> are compact tunable <b>electron focusing structures</b> using three cylindrical co-linear electrodes, with the middle one set at a higher voltage. We use it to focus electrons at the entrance of the hemispherical spectrometer so that they can follow the target trajectory with no loss. The standard design has three electrodes of the same length and same spacing to each other, as shown in the plot. </span>
                <v-img class="simulation-img" src="@/assets/EinzelLens/einzel_symm.png"></v-img>
                <span>Blue solid objects are cross sections of the three cylindrical electrodes; red tracks are full trajectories of every 100th electron in the simulation; green contours represent equipotentials calculated by IBSimu. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>As evident in the simulated plot, the waist of the focused beam is about <b>4 cm</b> across, which would be a problem for the hemispherical spectrometer because <b>deviation from the designed</b> trajectory will cause some electrons to hit the walls and <b>be lost</b> in the subsequent measurement.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Results</span>
                <span>Inspired by Riddle (1978), I explored <b>two additional designs</b> for the einzel lens: the <b>asymmetrical lens</b> with a longer middle electrode which is shifted towards the last electrode; and another asymmetrical lens with a longer middle electrode which is also shifted and <b>linearly tapered</b>. The two additional geometries along with examples trajectories are plotted. </span>
                <v-img class="simulation-img" src="@/assets/EinzelLens/einzel_asymm.png"></v-img>
                <v-img class="simulation-img" src="@/assets/EinzelLens/einzel_tapered.png"></v-img>
                <span>The focusing effect appears to be much better than that of the traditional einzel lens. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>Quantitatively, I plotted the gaussian fitted <b>deviation from the average trajectory intersection point</b>, which is a measure of the tightness of the focus, for all three designs. </span>
                <v-img class="chart-img" src="@/assets/EinzelLens/einzel_dev_x.png"></v-img>
                <span>Based on the plot we can see that the asymmetric design with no tapering has the tightest focus. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>The change in geometry does not disturb the <b>energy conservation</b> property of the einzel lens, but instead enhances it so some extent. Deviation from the initial energy is plotted below. </span>
                <v-img class="chart-img" src="@/assets/EinzelLens/einzel_dev_e.png"></v-img>
                <span>Again, the asymmetrical lens outperforms its counter parts. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-title">Conclusions</span>
                <span>The hemispherical spectrometer is still <b>being built</b> and fine-tuned and the einzel lens used will be modified to incorporate the asymmetrical design. This project is a direct application of my C++ knowledge on research and I’m excited about its realization in near future. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References and Attributions</span>
            </v-col>
        </v-row>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>References</span>
                <ul>
                    <li>Riddle, G.H.N. (1978) ‘Electrostatic einzel lenses with reduced spherical aberration for use in field emission guns’. <i>J. Vacuum Sci. Technol.</i> 15, 857.</li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>

<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"EinzelLens",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.physicsEntries){
                console.log("find theme color", entry)
                if(entry.title=="Einzel Lens Optimization"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>

<style scoped>
.simulation-img{
    max-width: 600px;
    margin: auto;
}
.chart-img{
    max-width: 500px;
    margin: auto;
}
</style>