<template>
  <transition name="float-and-explode">
    <div v-if="active" class="bubbleWrapper" :style='computedWrapperStyle'>
        <v-btn  class="bubble" :style="computedBtnStyle">
            <!--{{bubble.coord[0]}}, {{bubble.coord[1]}}, {{bubble.coord[2]}} 
            {{bubble.id}}:{{bubble.pos[0]}},{{bubble.pos[1]}}-->
        </v-btn>
    </div>
  </transition>
</template>

<script>
//import {calculateRadius, constructBackground} from '@/bubble/bubbleHelper'
import { mapState } from 'vuex'
//import moment from 'moment'

export default {
    name: 'Bubble',
    props: {
        top:{
            type: Number,
            required: true
        },
        left:{
            type: Number,
            required: true
        },
        p:{
            type: Number,
            required: true
        },
        a:{
            type: Number,
            required: true
        },
        d:{
            type: Number,
            required: true
        },
        zid:{
            type: Number, 
            required: true
        },
        active:{
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            gridSize: 20,
            //active: false
        }
    },
    computed:{
        r(){
            let r = (Math.abs(this.p)+Math.abs(this.a)+Math.abs(this.d))/3*2;
            return r===0 ? 0 : (Math.floor((r-1)/2)); 
        },
        width(){
            return ((this.r*2+4)*this.gridSize)
        },
        computedWrapperStyle(){
            //console.log(this.bubble)
            
                let top = this.top;
                let left = this.left;
                let zIndex = (this.id);
                //if(this.clicked==1)
                //    return { top: (top)+'px', left: (left)+"px", zIndex:361}
                //else
                let transitionTime = 0.5+Math.random(); 
                
                
                return { top: (top)+'px', left: (left)+"px", zIndex, transition:`top ${transitionTime}s, left ${transitionTime}s `}
    
            
        },
        computedBtnStyle(){
            let pleasure = this.p/7;
            let arousal = this.a/7;
            let dominance = this.d/7;
            let bg = `linear-gradient(60deg, rgba(242, 255, 38, ${Math.max(0, pleasure)}), `
                    +`rgba(224, 251, 0, ${Math.max(0, pleasure)})), `//yellow
                +`linear-gradient(240deg,  rgba(247, 38, 251, ${Math.max(0, -pleasure)}), `
                +`rgba(227, 8, 231, ${Math.max(0, -pleasure)})), `//purple
                +`linear-gradient(120deg, rgba(255, 38, 38, ${Math.max(0, arousal)}), `
                    +`rgba(251, 0, 0, ${Math.max(0, arousal)})), `//red
                +`linear-gradient(300deg, rgba(51, 255, 38, ${Math.max(0, -arousal)}), `
                +`rgba(39, 251, 0, ${Math.max(0, -arousal)})), `//green
                +`linear-gradient(180deg, rgba(251, 156, 38, ${Math.max(0, dominance)}), `
                +`rgba(167, 103, 24, ${Math.max(0, dominance)})), `//orange
                    +`linear-gradient(360deg, rgba(3, 46, 225, ${Math.max(0, -dominance)}), `
                    +`rgba(11, 24, 88, ${Math.max(0, -dominance)})) `;//blue
            let background = bg;
            //console.log(this.r)
      	    let width = this.width +'px';
            let height = this.width +'px';
            //if(this.clicked==1)
            //    return { background, width: width , height: height, 
            //    boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.12)"}    
            //else
            return { background, width: width , height: height}    
        },
    }
}
</script>

<style lang="scss" scoped>
  .bubbleWrapper{
    margin: 5px;
    display: inline-block;
    position: absolute;
    top:100%;
    left:0px;
    //transition: all .5s;
    //transition: left .5s;
  }

  .bubble{
  	width: 50px;
    min-width: 0px  !important;
    height: 50px;
	min-width: initial;
    background-blend-mode: multiply;
    border-radius: 50%;
    //box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.12);
    padding: 0px !important;
  }
    .float-and-explode-enter {
        top:100% !important;
        left:0px !important;
    }
    .float-and-explode-enter-active {
       // transition: all .5s;
    }
  .float-and-explode-leave-active {
    animation: explode .5s;
  }

  @keyframes explode {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
  }
      .float-and-explode-leave-to {
     opacity: 0;
    }
</style>