<template>
    <div>
        <v-row>
            <v-col id='hi-wrapper'>
                <AnimatedPolygon :sides="20" :update-interval="500" 
                :width="170" :min-radius="0.5" :fill-color="'#cccccc'"
                :style="{position:'absolute', zIndex: 1}"/>
                <h1 id="hi-message" class="hi-block">Oops, this page is not found. </h1>
                <h3 id="hi-statement" class="hi-block">How about the following options?</h3>
                <v-row id="personal-links" class="hi-block">
                    <v-col md='4' sm='6'><v-btn text href="yumon-app">My Homepage</v-btn></v-col>
                    <v-col md='4' sm='6'><v-btn text target="_blank" href="https://github.com/calthaz">My GitHub Profile</v-btn></v-col>
                </v-row>
            </v-col> 
        </v-row>
    </div>
</template>
<script>
import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
export default {
    name: 'NotFound',
    components: {
        AnimatedPolygon
    },
}
</script>
<style lang='scss' scoped>
@import '~vuetify/src/styles/styles.sass';

#hi-message{
  font-weight: 300;
  padding-top: 50px;
}

#hi-wrapper{
  padding-left:50px;
  position: relative;
  @media #{map-get($display-breakpoints, 'md-and-up')}{
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.hi-block{
  z-index: 2;
  position: relative;
  @media #{map-get($display-breakpoints, 'md-and-up')}{ 
      margin-left: 100px;
      display: flex;
  }
}
</style>
