<template>
    <div class='project-page'>
        <ProjectHero :src="'WallInspection/hero-registration.png'"
        :sides="7"  :min-radius="0.8" :theme-color="themeColor">
            <template v-slot:title>
                Robotic Wall Inspection
            </template>
            <template v-slot:subtitle>
                A Computational Framework for Robotic Quality Assessment and Management in Construction
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">This project presents a framework for a robot to perform automatic quality assessment and management where such tasks could be done manually but tediously in environments that could be dangerous. The framework is composed of three parts: motion planning, defect detection, and defect registration.  The motion planning component generates efficient robotic path for autonomous exploration and surface inspection. The defect detection component quantifies surface anomalies within a user-defined area of interests through multiple sensor measurements. The defect registration component localizes the detected defects and registers the defects to a site model. I worked on defect detection with computer vision and machine learning (image classification) and registering them to an interior wall model using robotic arm poses and environment data. This project is a hopeful starting point to aid construction workers with robotic technologies. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: Jingyang Liu, <b>Yumeng Zhuang</b>, and Joshua Bard</span>
                <span>My Role: Defect detection and registration</span>
                <span>Time: September 2021 – December 2022</span>
                <span>ICRA 2022 Construction Robotics Workshop. 
                <a target="_blank" href="https://doi.org/10.22260/ICRA2022/0011">https://doi.org/10.22260/ICRA2022/0011</a></span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Introduction</span>
                <span>Surface quality assessment and management play a key role in construction operations for safety and quality assurance. Currently, most quality assessment and management tasks are conducted manually by an inspector using a visual inspection approach or contact-type measurement devices such as measuring tapes, levels, and calipers. Manual inspection methods can be tedious, costly, and dangerous, especially when the work environment is hazardous and inaccessible. </span>
                <span>To overcome these limits, the main goal of this project is to provide a computational framework for robots to assist humans by traversing and inspecting the area of interest on a job site where the environment may not be known a priori and performing remote non-contact defect detection and registration at different levels of details. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Experiment Setup</span>
            </v-col>
            <v-col md='12' sm='12'>
                <v-img
                    src="@/assets/WallInspection/experiment_setup_compressed.jpg"
                ></v-img>
            </v-col>
            <v-col md='12' sm='12'>
                <span>Experiment setup (a) a 6 DoF industrial robot on a 5800 mm linear track and a interior wall mockup of 1500 mm (L) x 745 mm (W) x 1980 mm (H) (b) an end-effector sensor kit including RGB-D cameras (Kinect V2, Intel Realsense D415), laser range finders and a laser module (c) the robot highlights a detected surface defect with a laser cross-line.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Motion Planning</span>
                <span>The motion planning component generates near-optimal paths for robots to explore the surrounding environment and scan the target area. The component maximizes coverage in three stages. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='8' sm='12'>
                <span class='section-subtitle'>Stage 1</span>
                <span>At the first stage, the environment is <b>unknown</b>, the component returns the exploration path by searching and identifying the frontier points between explored and unknown parts of the map. </span>
                <span>The robot first looks for a frontier and then chooses the one with the maximum information gain weighted by its cost to reach. After the goal frontier is selected, the planner generates a path to move the robot 30 mm towards the frontier and look in the direction of the frontier through an Inverse Kinematic (IK) solver unless the planner cannot find a valid IK solution. The exploration procedure terminates when the void voxels within the bounded space are classified as either free or occupied.</span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img
                    src="@/assets/WallInspection/mp-phase1.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='5' sm='12'>
                <span class='section-subtitle'>Stage 2</span>
                <span>At the second stage, a user can <b>define a target volume</b> within the partially known environment for detailed reconstruction, the component plans a next best view based on the information gain of each sampled view candidate. We first combine the voxels in the target volume into a binary status — “occupied” and “unoccupied”. The system then uniformly samples a set of viewpoints within IK reach on a sphere to capture the most variations of perspectives that can cover the target volume.</span>
            </v-col>
            <v-col md='7' sm='12'>
                <v-img
                    src="@/assets/WallInspection/mp-phase2_compressed.jpg"
                ></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <span class='section-subtitle'>Stage 3</span>
                <span>At the third stage, a user can define an <b>area of interest</b> within the known environment for surface inspection. we use a region-growing approach based on smoothness constraints to cluster point clouds into surface patches. The remote inspector can then choose a target surface or an area of interest on a surface for local scanning.</span>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img
                    src="@/assets/WallInspection/mp-phase3_compressed.jpg"
                ></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Defect Detection</span>
                <span>This component aims at identifying defects before finishing and painting and after the gypsum board installation. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <span class='section-subtitle'>Board deformation</span>
                <span>We first use a principal component analysis-based approach to <b>fit a plane</b> (in blue and pink) within the selected point cloud. </span>
                <span>Then we can calculate the orthogonal distance between each point and the plane to evaluate surface flatness errors and calculate the connecting angle between two adjacent surfaces by computing the angle between their <b>normal vectors</b>.</span>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img
                    src="@/assets/WallInspection/def-surf.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <span class='section-subtitle'>Screwing Failures</span>
                <span>Screwing failures need to be detected in two steps. </span>
                <span>First, screws are located based on 2D images. We first <b>detect circles</b> with Hough circles as potential screw regions. We then crop these circles as regions of interest. The regions are <b>classified</b> as screw or non-screw with a fine-tuned Xception model. We built our own dataset with screw pictures taken on interior wall surfaces under different lighting conditions.</span>
                <span>Their conditions are then measured using <b>a laser range finder</b>. We determine whether the screw is defective by measuring its surface height compared with its surrounding surface points. </span>
                <span class='section-subtitle'>Surface Anomalies</span>
                <span>We apply Contrast Limited Adaptive Histogram Equalization (CLAHE) to reduce the effect of lighting changes. Then we extract edges with a Canny edge detector and use morphological operations to cover the regions with dense edges, which correspond to the <b>rougher texture of surface anomalies</b>. With these regions, we filter out the regions with contour areas greater than one standard deviation above average and classify them as surface anomalies. </span>

            </v-col>
            <v-col md='6' sm='12'>
                <v-img
                    src="@/assets/WallInspection/def-screw-patch.png"
                ></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Defect Registration</span>
                <span>The detected surface defects need to be registered to a site scene model, an inspector can thus localize the surface defects and authorize surface repair tasks. The registration process is completed in two phases - common source registration and cross-source registration. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <span class='section-subtitle'>2D to 3D registration</span>
                <span>We first register the detected defects to a point cloud paired with the current robot pose and then fuse the point cloud acquired between different robot poses into a local model. The detected defects are registered to a point cloud by applying the transform matrix that is known after sensor calibration.</span>
            <!-- </v-col>
            <v-col md='3' sm='12'> -->
                <v-img id = "reg-2d3d" contain
                    src="@/assets/WallInspection/reg-2d3d.png"
                ></v-img>
            </v-col>

        <!-- </v-row>
        <v-row class="wrapper"> -->
            <v-col md='6' sm='12'>
                <span class='section-subtitle'>3D to 3D Registration</span>
                <span>Local models scanned from different frames (dense to dense models) are registered by Iterative Closest Point (ICP) algorithm. To register the scene model with the local model, we used the clustering iterative closest point (CICP) approach. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Results</span>
                <span>According to the surface inspection results, the mockup wall has 8.18% area identified as not flat (deviation from the fitted plane > 10 mm). The maximum displacement on the drywall is 28.95 mm. The angle between the two surfaces is −0.54 degrees deviating from the expected angle (90 degrees). The total area of surface anomalies is 85661.28 mm2, accounting for 3.86% of the total surface area. 1 out of 33 of screws detected is identified as loose.</span>
            </v-col>
            <v-col md='12' sm='12'>
                <v-img
                    src="@/assets/WallInspection/Fig_3_Result_of_Surface_Evaluation_compressed.jpg"
                ></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Conclusion</span>
                <span>In this project, we validate the framework of robot-assisted surface quality assessment with three stages: motion planning, defect detection, and defect registration. This framework can be applied in dangerous work environments that need quality assessment, and to relieve workers of tedious manual inspection. </span>
                <span>Future direction of this work includes looking for more efficient 3D exploration process for motion planning, a more accurate registration process that uses multiple sensor inputs at different resolutions, and more types of defects that can be managed. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References</span>
                <ul>
                    <li>Please see <a target="_blank" href="https://doi.org/10.22260/ICRA2022/0011">our paper</a> for full references.</li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
//import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
import ProjectHero from '@/components/ProjectHero.vue'
export default {
    name:"WallInspection",
    components:{
        ProjectHero,
    },
    data: ()=>{
        return {
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.interactionEntries){
                console.log("find theme color", entry)
                if(entry.title=="Robotic Wall Inspection"){
                    return entry.titleBg
                }
            }
            return "#000000"
        },
    },
    mounted(){
    }
}
</script>
<style lang="scss" scoped>

.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(142, 36, 170, 0.3);
    }
}

div#bubble-space{
    position: relative;
    height: 500px;
    overflow: hidden;
}

div#bubble-space-demo{
    position: relative;
    height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    span{
        margin: 50px
    }
}

// .illustration-icon{
//     width: 150px;
//     height: 150px;
//     margin: auto;
// }

#reg-2d3d{
    max-height: 350px;
    // background-color: rgb(221, 221, 221);
    /*
    margin: auto;*/
}
.illustration{
    max-width: 250px;
    max-height: 250px;
    margin: auto;
}
.icon-text{
    display: inline-block;
}
.sam{
    max-width: 650px;
    margin: auto;
}
</style>