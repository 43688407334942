<template>
    <div>
        <!--<v-row class='wrapper'>
            https://v2.vuetifyjs.com/en/components/chips/#custom-list
            <v-col cols = '12'>
              <v-card outlined>
                <v-toolbar
                flat
                color="transparent"
                >
                <v-toolbar-title>Topics</v-toolbar-title>
                </v-toolbar>

                <v-container class="py-0">
                <v-row
                    align="center"
                    justify="start"
                >
                    <v-col
                    v-for="(selection, i) in selections"
                    :key="selection.text"
                    class="shrink"
                    >
                    <v-chip
                        :disabled="loading"
                        close
                        @click:close="selected.splice(i, 1)"
                    >
                        <v-icon
                        left
                        v-text="selection.icon"
                        ></v-icon>
                        {{ selection.text }}
                    </v-chip>
                    </v-col>
                </v-row>
                </v-container>

                <v-divider v-if="!allSelected"></v-divider>

                <v-list>
                <template v-for="item in categories">
                    <v-list-item
                    v-if="!selected.includes(item)"
                    :key="item.text"
                    :disabled="loading"
                    @click="selected.push(item)"
                    >
                    <v-list-item-avatar>
                        <v-icon
                        :disabled="loading"
                        v-text="item.icon"
                        ></v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item>
                </template>
                </v-list>

                <v-divider></v-divider>
            </v-card>
            </v-col>
        </v-row>-->
        <v-row class='wrapper'>
            <h1 class="project-section-title">Interaction</h1>
        </v-row>
        <v-row dense class='wrapper'>
            <v-col
                v-for="entry in interactionEntries"
                :key="entry.title"
                cols="12"
                :md="entry.col"
            >
                <EntryCard :entry="entry"/>
            </v-col>
        </v-row>
        <!--<v-row class='wrapper'>
            <h1 class="project-section-title">Computaion + Design</h1>
        </v-row>
        <v-row dense class='wrapper'>
            <v-col
                v-for="entry in compDesEntries"
                :key="entry.title"
                cols="12"
                :md="entry.col"
            >
                <EntryCard :entry="entry"/>
            </v-col>
        </v-row>-->

        <v-row class='wrapper'>
            <h1 class="project-section-title">Physics</h1>
        </v-row>
        <v-row dense class='wrapper'>
            <v-col
                v-for="entry in physicsEntries"
                :key="entry.title"
                cols="12"
                :md="entry.col"
            >
                <EntryCard :entry="entry"/>
            </v-col>
        </v-row>

        <v-row class='wrapper'>
            <h1 class="project-section-title">Work Experience</h1>
        </v-row>
        <v-row dense class='wrapper'>
            <v-col
                v-for="entry in workEntries"
                :key="entry.title"
                cols="12"
                :md="entry.col"
            >
                <EntryCardWork :entry="entry"/>
            </v-col>
        </v-row>

        <v-row class='wrapper'>
            <h1 class="project-section-title">Art + Design Works</h1>
        </v-row>
        <v-row dense class='wrapper'>
            <v-col
                v-for="entry in artEntries"
                :key="entry.title"
                cols="6"
                :md="entry.col"
            >
                <EntryCardArt :entry="entry"/>
            </v-col>
        </v-row>
        <!--<v-row class='wrapper'>
            <h1 class="project-section-title">Writings</h1>
        </v-row>
        <v-row dense class='wrapper'>
            <v-col
                v-for="entry in writingEntries"
                :key="entry.title"
                cols="6"
                :md="entry.col"
            >
                <EntryCardWriting :entry="entry"/>
            </v-col>
        </v-row>-->
        <v-row class='wrapper'>
            <h1 class="project-section-title">Contact Me?</h1>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>Email: yumengzh at andrew dot cmu dot edu</span>
                <span>Tel: +1 (626) 898-0514</span>
                <span>Pittsburgh, Pennsyvania</span>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import EntryCard from '@/components/EntryCard.vue'
import EntryCardArt from '@/components/EntryCardArt.vue'
import EntryCardWork from '@/components/EntryCardWork.vue'
//import EntryCardWriting from '@/components/EntryCardWriting.vue'
import { mapState } from 'vuex'

export default {
    name: 'ProjectPage',
    data(){
        return {
            items: [
                {
                text: 'Nature',
                icon: 'mdi-nature',
                },
                {
                text: 'Nightlife',
                icon: 'mdi-glass-wine',
                },
                {
                text: 'November',
                icon: 'mdi-calendar-range',
                },
                {
                text: 'Portland',
                icon: 'mdi-map-marker',
                },
                {
                text: 'Biking',
                icon: 'mdi-bike',
                },
            ],
            loading: false,
            search: '',
            selected: [],
        }
    },
    components: {
        //HelloWorld
        EntryCard,
        EntryCardArt,
        EntryCardWork,
        //EntryCardWriting
    },
    computed:{
        ...mapState(['interactionEntries', 'compDesEntries', 'physicsEntries', 'workEntries', 'artEntries', 'writingEntries']), 
        allSelected () {
            return this.selected.length === this.items.length
        },
        categories () {
            const search = this.search.toLowerCase()

            if (!search) return this.items

            return this.items.filter(item => {
            const text = item.text.toLowerCase()

            return text.indexOf(search) > -1
            })
        },
        selections () {
            const selections = []

            for (const selection of this.selected) {
            selections.push(selection)
            }

            return selections
        },
    },
    watch:{
        selected () {
            this.search = ''
        },
    },
    methods: {
      next () {
        this.loading = true

        setTimeout(() => {
          this.search = ''
          this.selected = []
          this.loading = false
        }, 2000)
      },
    },
}
</script>
<style lang='scss' scoped>
h1.project-section-title {
    //padding-left: 70px;
    padding-left: 7px;
    padding-right: 7px;
    display: block;
    //background: black;
    //color: white;
    //font-family: "Courier New", Courier, monospace;
    //margin-left: -40px;
}
</style>