<template>
    <div class='project-page'>
        <ProjectHero :src="'ReconfigMM/reconfMM-lid-tilt_compressed.jpg'"
        :sides="25"  :min-radius="0.4" :theme-color="themeColor">
            <template v-slot:title>
                Reconfigurable Elastic Metamaterial
            </template>
            <template v-slot:subtitle>
                Enabling End-Users to Customize Material Elasticity with Engineered Structures
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">This project presents a metamaterial that can be reconfigured by users for varied bounciness. This material consists of a generic sheet with engineered cell structures and a configuration sheet to control the specific elasticity response. Specifically, there are two types of cell structures, spring cells that are better at conserving kinetic energy and thus more springy and damper cells that dissipate more energy. My task in this project was to model the elastic response of the combination of spring and dampers cells so that end-users could generate the specific configuration sheet to achieve their desired bounciness. I modeled the response with coupled damped harmonic oscillator and programmed a configuration editor in Rhino and Grasshopper which can export 3D printable configurations. I am glad that my knowledge in physics could help democratize the usage of metamaterial in the broader community.  
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: Willa Yunqi Yang, <b>Yumeng Zhuang</b>, Luke Darcy, Grace Liu, Alexandra Ion</span>
                <span>My Role: Physics modeling of material</span>
                <span>Time: January 2022 - August 2022</span>
                <span>In Proceedings of the 35th Annual ACM Symposium on User Interface Software and Technology (UIST '22). Association for Computing Machinery, New York, NY, USA, Article 67, 1-13. 
                <a target="_blank" href="https://doi.org/10.1145/3526113.3545649">https://doi.org/10.1145/3526113.3545649</a>
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper" justify="center">
            <v-col md='12' sm='12'>
                <span class='section-title'>Background</span>
            </v-col>
            <v-col md='6' sm='12' >
                <v-img src="@/assets/ReconfigMM/reconfMM-sheet-only.png"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <span class="mt-5">Additive manufacturing, such as 3D printing, has allowed end-users to customize a lot of different materials to achieve different material properties, such as shock absorption and stiffness control. Metamaterials are a type of material with carefully designed lattice structures to give them different properties. Combined with 3D printing, these materials can now be manufactured with relative ease. However, the design and printing of metamaterials often require expert knowledge and can be very time consuming due to their fine structure. </span>
                <span>This project presents the concept of a <i>generic</i> metamaterial sheet that can be reconfigured <i>after</i> being mass produced in a factory setting. Users could therefore minimize the effort of designing the material, and focus on tuning its properties. </span>
            </v-col>
            
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Material Design</span>
            </v-col>
            <v-col md='9' sm='12'>
                <span>The generic elastic metamaterial consists of two types of cells (S for spring, D for damper) arranged in a checkerboard pattern. The cells can be coupled locally and selectively to configure the material’s elasticity. In this section describes the mechanical design of the cells and the configuration layer for the generic sheet.</span>
            </v-col>
            <v-col md='3' sm='12'>
                <v-img src="@/assets/ReconfigMM/reconfMM-checkered_compressed.jpg"></v-img>
            </v-col>
            
        </v-row>
        <v-row class="wrapper" justify="space-around">
            <v-col sm='12'>
                <span class='section-subtitle'>Spring and Damper Cells</span>
            </v-col>
            <v-col md='4' sm='8' class='text-center'>
                <v-img class="illustration-icon"
                src="@/assets/ReconfigMM/reconfMM-sprCompressed.png" cover></v-img>
                <span class="illustration-text-topm">Spring cells are constructed by two stacking ovals, with thick walls that enhance the energy storing and returning ability. </span>
            </v-col>
            <v-col md='4' sm='8' class='text-center'>
                <v-img class="illustration-icon"
                src="@/assets/ReconfigMM/reconfMM-dmpCompressed.png" cover></v-img>
                <span class="illustration-text-topm">Damper cells dissipate energy through its softer structure that scrapes its frame when compressed. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-subtitle'>Coupling</span>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img src="@/assets/ReconfigMM/reconfigMM-coupling-lid-detail_compressed.jpg"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <span>The tunable bounciness is achieved by coupling different amounts of spring and damper cells through a <i>coupling lid</i> (in black). This lid is easily 3D printable by end-users. The protruding hooks of the lid compress the corresponding cells below, while the flat areas of the lid leaves the cells inactive. </span>
                <span>Different coupling patterns brings about different bounciness felt by the lid. </span>
            </v-col>
            
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Applications</span>
                <span>I designed the bubble space to convey the message that all emotions are <b>temporary</b> like bubbles, yet they contain snapshots of our <b>thinking patterns and emotional health</b>. By using color codes and varying sizes, I allow all emotions to appear on the stage, but nothing can emphasize more the fleeting nature of emotions than setting a timeframe for display. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md="4" sm='12'>
                <span class='section-subtitle'>Shoe Sole</span>
            <!-- </v-col>
            <v-col md='4' sm='12'> -->
                <v-img src="@/assets/ReconfigMM/reconfMM-shoe-sole.png"></v-img>
            <!-- </v-col>
            <v-col md='8' sm='12'> -->
                <span class="mt-5">(a) Users can buy a generic shoe and reconfigure the shoe sole for different physical activities: (b) long-distance running; (c) playing volleyball. S are springs, and D are dampers. </span>
            </v-col>
        <!-- </v-row>
        <v-row class="wrapper"> -->
            <v-col md="4" sm='12'>
                <span class='section-subtitle'>Piano Keys</span>
            <!-- </v-col>
            <v-col md='6' sm='12'> -->
                <v-img src="@/assets/ReconfigMM/reconfMM-piano.png"></v-img>
                <span class="mt-5">(a) Users can change piano keys and (b) play the piano on the material with customized key stiffness. (c) The feature of multi-layered tilting allows the height difference between black and white keys.</span>
            <!-- </v-col>
            <v-col md='6' sm='12'> -->
                
            </v-col>
        <!-- </v-row>
        <v-row class="wrapper"> -->
            <v-col md="4" sm='12'>
                <span class='section-subtitle'>Ball Game</span>
            <!-- </v-col>
            <v-col md='6' sm='12'> -->
                <v-img src="@/assets/ReconfigMM/reconfMM-ping-pong-material.png"></v-img>
            <!-- </v-col>
            <v-col md='6' sm='12'> -->
                <span class="mt-5">A ball game that combines pong and battleship. (a) Users throwa ball onto the material, but could previously configure (b) the local elasticity to make it difficult for their opponent to catch the ball. The map is concealed as the configuration layer looks identical from the top.</span>
            </v-col>  
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Material Modeling</span>
                <span>My task was to model the coupled response and build a coupling lid editor that outputs the lid design for the desired bounciness. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-subtitle'>Experiment</span>
            </v-col>
            <v-col md='12' sm='12'>
                <span>Willa Yang and I collected the experiment data of bounce heights of metal balls dropped onto our material. The active spring and dampers cells are arranged from the center of the material sheet and spread radially outwards. Different numbers of spring and damper cells give the material different bounciness, thus give rise to different bounce heights. Different masses of the metal balls also determine how the material will respond, which is useful for customizing the material for specific needs.</span>
                <v-img src="@/assets/ReconfigMM/reconfMM-raw-reading.jpg" height="350" contain></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper" justify="center">
            <v-col sm='12'>
                <span class='section-subtitle'>Results</span>
            </v-col>
            <v-col sm='12'>
                <span>We converted the bounce height into the energy loss in the interaction to take into account the different masses. The results of our experiment largely follow our intuition. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='6' sm='12'>
                <ul>
                    <li>If we only increase the number of spring cells without any damper cells, the energy loss increases mildly because more springs are compressed and dissipates energy.</li>
                    <li>If we only increase the number of damper cells, the energy loss is nearly constant because the energy loss is saturated, and the ball starts to interact to what seems to be a stiff mass regardless of the number of damper cells.</li>
                    <li>If we keep spring cells constant, and increase the number of damper cells, the energy loss only sees a mild increase, which is inline with the constant nature of damper cells. </li>
                    <li>If we keep the number of damper cells constant, and increase the number of spring cells, we see an interesting phenomenon that the initial increase of spring cells brings down the energy loss, as the spring offer energy return more efficiently than the damper cells.</li>
                </ul>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img src="@/assets/ReconfigMM/reconfMM-dcsv.png"></v-img>
                <span class="illustration-text">This graph shows the relationship between energy loss (J) and mass (g) for different numbers of spring cells while keeping the six dampers there. The scatter points with error bars show the measured data, while the dashed lines show the prediction of our model explained below.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-subtitle'>Model</span>
                <span>I model the spring-damper mixture system as a damped harmonic oscillator.</span>
                <span>For a single simple damped oscillator, the commonly used quality factor <i>Q</i> describes how well an oscillating system preserves energy [1].</span>
            </v-col>
            <v-col sm='12'>
                <v-img class = "equation-img" src="@/assets/ReconfigMM/reconfMM-Q-single.png" contain></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>We visualize the meaning of this factor in the following diagram.</span>
            </v-col>
            <v-col sm='9'>
                <v-img src="@/assets/ReconfigMM/reconfMM-q-explanation.png" contain></v-img>
            </v-col>
            <v-col sm='12'>
                <span>This figure shows the energy dissipation in a damped harmonic oscillator in each cycle. Q factor reflects the decay rate of the bounce height. Left: Schematics of the experiment set up. <i>ks , kd , bs</i> , and <i>bd</i> are constants associated with the spring and damper cells, and they are combined into k and b of the whole system. Right: A sample graph showing the decay of the bounce height over time, and the highest starting point can be thought of as the drop height, and the first peak the first bounce height that we measure.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>As we have multiple dampers and springs aligned on a surface, we approximate the system such that the unit cells share the same deformation. Therefore, the force acting upon the dropped mass during collision is the sum of that from all springs and dampers combined.</span>
                <span>As a result, the aggregate spring and damping constants are the sum of their individual spring and damping constants, respectively.</span>
                <span>If we have <i>s</i> springs, and <i>d</i> dampers, the aggregate spring constant is <i>sks +dkd</i> , and the aggregate damping constant is <i>sbs +dbd</i> .The subscript denotes which type of cell the constant belongs to, since springs and dampers have different properties.</span>
                <span>We plug in these aggregated constants to the Q expression above and added a free parameter <i>t</i> to measure the energy loss in other processes, such as interacting with the plastic lid.</span>
                <v-img class = "equation-img" src="@/assets/ReconfigMM/reconfMM-x-multi.png" contain></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>To bound the energy loss within 100% of the origin, we note that a common method is to use an envelope function <i>E</i> to model the approaching of 100%. <i>E(x)</i> is chosen to be exponentially approaching 100% the maximum possible loss <i>L</i> of the system exponentially. </span>
                <v-img class = "equation-img" src="@/assets/ReconfigMM/reconfMM-Ex.png" contain></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span>We fitted all the parameters with Python’s SciPy package. Over all data points, we obtain a relative approximate residual of 3%. The prediction from our physics model is plotted in the spring-varying diagram above as dashed lines. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Editor</span>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img src="@/assets/ReconfigMM/recolored_x6y6_0.3_80.png"></v-img>
            </v-col>
            <v-col md='6' sm='12'>
                <v-img src="@/assets/ReconfigMM/recolored_x6y6_0.3_25.png"></v-img>
            </v-col>
            <v-col md='12' sm='12'>
                <span>Based on the physics model above, I built an editor interface with Rhino and Grasshopper to support novice users in configuring their material by computing the number of active spring and damper cells in their material to help them achieve their desired configuration of elasticity. </span>
                <span>Users can define the patch size, the mass they intend to use and the target bounce height relative to a drop height of 330 mm. Our algorithm computes the number of cells and distributes them evenly across the material. Users can preview the bouncing ball and export the 3D printable configuration layer geometry as a STL file. </span>
            </v-col>
            
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col sm='12'>
                <span class='section-title'>Conclusion</span>
                <span>This project proposes a metamaterial that can be decomposed into a generic, mass-producible, and complex material sheet and a user-printable configuration layer. This design allows end-users to configure the elasticity of the material with ease. </span>
                <span>By modeling the spring and damper cells, I was able to design a tool that help end-users to customize our material at home. I believe that this is a valuable contribution to the maker community that could benefit from the ease of access to material with variable elasticity.</span>
                <span>Looking forward, I see this material being used in more scenarios beyond being on a flat surface, since the base material sheet could be deformable. We could also experiment with varying cell sizes and designs to enhance material properties. For the editor, I could experiment with more simulation visualization methods to convey elasticity. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References</span>
                <ol>
                    <li>Daniel Kleppner and Robert Kolenkow. 2014. <i>An Introduction to Mechanics</i>. Cambridge University Press.</li>
                </ol>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import colors from 'vuetify/lib/util/colors';
//import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
import ProjectHero from '@/components/ProjectHero.vue'
//import Bubble from '@/components/Bubble.vue'

export default {
    name:"ReconfigMM",
    components:{
        //AnimatedPolygon
        ProjectHero,
        //Bubble
    },
    data: ()=>{
        return {
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.interactionEntries){
                console.log("find theme color", entry)
                if(entry.title=="Reconfigurable Metamaterial"){
                    return entry.titleBg
                }
            }
            return "#000000"
        },
    },
}
</script>
<style lang="scss" scoped>

.wrapper{
    .section-subtitle, .section-title{
        //background-color: rgba(142, 36, 170, 0.3);
    }
}

.illustration-icon{
    width: 150px;
    height: 150px;
    margin: auto;
}
.illustration{
    max-width: 250px;
    max-height: 250px;
    margin: auto;
}
.illustration-text-topm{
    margin-top: 20px;
}
.icon-text{
    display: inline-block;
}
.sam{
    max-width: 650px;
    margin: auto;
}
.equation-img{
    max-height: 70px;
}
</style>