<template>
<v-hover v-slot="{ hover }">
    <v-card hover :class="{ 'on-hover': hover }" :style="styleTitle" class='entry-card'>
        <v-list-item three-line>
            <v-list-item-avatar
                tile
                size="80"
                color="grey"
            >
            <v-img
                :src="getImgUrl"
                class="white--text align-end white"
                :height="computedHeight"
                style="filter:none"
                contain
            ><!----:gradient="'to bottom, rgba(0,0,0,0), '+entry.subtitleBg"-->         
            </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
            <div class="text-overline mb-1">
            {{entry.identifier}}
            </div>
            <v-list-item-title class="text-h5 mb-1">
            {{entry.title}}
            </v-list-item-title>
            <v-list-item-subtitle>{{entry.subtitle}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</v-hover>
</template>
<script>
export default {
    name: 'EntryCard',
    props: {
        entry: {
            type: Object,
            required: true
        },
    },
    mounted(){
        //console.log(this.entry)
    },
    computed:{
        getImgUrl() {
            //console.log(this.entry.bg)
            return require('../assets/'+this.entry.bg)
        },
        styleTitle: function() {
            return {
            '--title-bg': this.entry.titleBg,
            'font-size': '1rem',
            }
        },
        styleSubtitle(){
            return {
                '--subtitle-bg': this.entry.subtitleBg
            }
        },
        joinedTags(){
            let s = "";
            for (let i=0; i<this.entry.tags.length-1; i++){
                s+=this.entry.tags[i];
                s+='+';
            }
            s+=this.entry.tags[this.entry.tags.length-1];
            return s
        },
        computedHeight () {
            //console.log(this.$vuetify.breakpoint.sm);
            if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
                return "90vw";
            }
            if(this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
                return "20vw";
            }
            return "25vw"
        },
    }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.v-card__title{
    transition: background-color .3s ease
}
    
.v-card.entry-card{
    //border: 3px solid var(--title-bg)
    box-shadow: none !important;
}
//.v-card__title.on-hover{
//    background-color: var(--title-bg)
//}
    
.tags{
    font-weight: 600;
}
//.v-card__subtitle.on-hover{
//    background-color: var(--subtitle-bg)
//}
        
</style>