<template>
    <div>
        <v-row class="d-flex project-title" align="center" justify="center">
            <v-col cols="12" class="d-flex flex-column text-center">
                <AnimatedPolygon :sides="sides" :update-interval="updateInterval" 
                :width="50" :min-radius="minRadius" :fill-color="themeColor"
                :style="{zIndex: 1, margin: 'auto', opacity: 0.5}"/>
                <h1 class="display-1 font-weight-thin">
                    <slot name="title">Title</slot>
                </h1>
                <h4 class="subheading">
                    <slot name="subtitle">This is a very long subtitle.</slot>
                </h4>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-img v-if="src" :src="getImgUrl" aspect-ratio="2">
            <!--<v-row class="project-title d-none d-md-flex" align="center" justify="center">
                <v-col cols="12" class="d-flex flex-column text-center">
                    <AnimatedPolygon :sides="sides" :update-interval="updateInterval" 
                    :width="50" :min-radius="minRadius" :fill-color="themeColor"
                    :style="{zIndex: 1, margin: 'auto', opacity: 0.5}"/>
                    <h1 class="display-1 font-weight-thin">
                        <slot name="title">Title</slot>
                    </h1>
                    <h4 class="subheading">
                        <slot name="subtitle">This is a very long subtitle.</slot>
                    </h4>
                </v-col>
            </v-row>-->
        </v-img>
    </div>
</template>
<script>
import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
export default {
    name: "ProjectHero",
    components:{
        AnimatedPolygon
    },
    props:{
        src:{
            type:String,
            default: null
        },
        themeColor:{
            type:String,
            default: "#000000"
        },
        sides:{
            type:Number,
            default: 10
        },
        minRadius:{
            type:Number,
            default: 0.5
        },
        updateInterval:{
            type: Number,
            default: 500
        }
    },
    mounted(){
        console.log("getting image", this.src)
    },
    computed:{
        getImgUrl() {
            //console.log(this.entry.bg)
            if(this.src)
                return require('../assets/'+this.src)
            else 
                return null
        },
    }
}
</script>
<style lang='scss' scoped>
@import '~vuetify/src/styles/styles.sass';

.project-title{
    min-height: 0px;
    background-color: rgba(255, 255, 255 ,0.5);
    @media #{map-get($display-breakpoints, 'md-and-up')}{
        min-height: 200px;
    }
    h1, h4{
        //margin-left: 50px;
        z-index: 2;
        position: relative;
        
        @media #{map-get($display-breakpoints, 'md-and-up')}{ 
            //margin-left: 100px;
            //display: flex;
        }
    }
}    
</style>