<template>
  <div class="home">
    <v-row>
      <v-col id='hi-wrapper'>
        <AnimatedPolygon :sides="20" :update-interval="500" 
        :width="170" :min-radius="0.3" :fill-color="themeColor"
        :style="{position:'absolute', zIndex: 1, opacity: 0.5}"/>
        <h1 id="hi-message" class="hi-block">About Me</h1>
        <h3 id="hi-statement" class="hi-block">A little more about who I am.</h3>
        <v-row id="personal-links" class="hi-block d-flex flex-row-reverse">
          <v-col md='4' sm='6'><v-btn text target="_blank" href='../cv_aejrn091221.pdf'>My CV</v-btn></v-col>
          <v-col md='4' sm='6'><v-btn text target="_blank" href="https://www.linkedin.com/in/%E9%9B%A8%E8%90%8C-%E5%BA%84-8a8482173/?locale=en_US">My LinkedIn Profile</v-btn></v-col>
        </v-row>
      </v-col> 
    </v-row>
    <v-row class="wrapper" style="margin-top: 0px">
      <v-col md='3' sm='12'>
        <v-img src="@/assets/profile.jpg"></v-img>
      </v-col>
      <v-col md="6" sm='12'>
          <span>Hi!</span>
          <span>I graduated from the <a target="_blank" href="https://soa.cmu.edu/computational-design"> Master of Science in Computational Design</a> program at Carnegie Mellon University in May 2023. Before that, I graduated from UCLA with a bachelor’s degree in Physics and minors in Math and German.</span> 
          <span>I'm actively applying for PhD positions around the world. </span>
          <span>I’m a curious explorer in creative technology, machine learning, computational fabrication, and Human Computer Interaction. </span>
          <span>I’m a curious thinker in Design Research and critical making of technology. </span>
          <span>I’m a curious participant in painting, crocheting, manipulating sounds (guitar and poor Arduino speakers), and hiking both in large cities and in the wilderness. </span>
      </v-col>
    </v-row>

    <v-row class="wrapper">
      <v-col sm='12'>
        <span>Pronouns: she/her</span>
        <span>Email: yumengzh at andrew dot cmu dot edu</span>
        <span>Tel: +1 (626) 898-0514</span>
        <span>Pittsburgh, Pennsyvania</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
import { mapState } from 'vuex'
import colors from 'vuetify/lib/util/colors';
//import ProjectPage from "@/views/ProjectPage.vue"

export default {
  name: 'Home',
  components: {
    AnimatedPolygon,
    //ProjectPage
  },
  data: () => ({
    themeColor: colors.orange.darken2
  })
}
</script>
<style lang='scss' scoped>
@import '~vuetify/src/styles/styles.sass';

#hi-message{
  font-weight: 300;
  padding-top: 50px;
}
#personal-links{
  max-height: 70px;
}

#hi-wrapper{
  padding-left:50px;
  position: relative;
  @media #{map-get($display-breakpoints, 'md-and-up')}{
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.hi-block{
  z-index: 2;
  position: relative;
  @media #{map-get($display-breakpoints, 'md-and-up')}{ 
      margin-left: 100px;
      display: flex;
  }
}
</style>
