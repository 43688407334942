<template>
    <div class='project-page'>
        <ProjectHero :src="'EchoMask/still_from_vid_sit_back.png'"
        :sides="7"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                Echo Mask
            </template>
            <template v-slot:subtitle>
                A Discursive Approach to Tangible Rumination on the Meaning of Thoughts
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Overview</span>
                <span class="project-statement-text">The Echo Mask is an interactive inflated mask that examines how digital devices could mediate rumination by reducing the meaning of thoughts. Inspired by somaesthetic design and cognitive defusion, I designed the repetition, distortion, and playback of user’s voiced thoughts, which bring the focus of the user to the sound instead of the meaning of the thoughts. The user repeats into the Echo Mask a phrase as they hold the mask in a specific shape. A machine learning model associates the shape with the sound segment. Then the device produces a mixed, chaotic playback based on its machine learning prediction as it loses certainty.The Echo Mask illustrates the ability of digital devices to participate in rumination and suggests a design approach that could enhance our experience of the here and now.
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: <b>Yumeng Zhuang</b> and Daragh Byrne</span>
                <span>Part of Independent Thesis Project</span>
                <span>Time: September 2022 – April 2023</span>
                <span>Published at ACM Designing Interactive Systems 2023 Demonstration. <a target="_blank" href="https://doi.org/10.1145/3563703.3596660">[Link]</a></span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Video Demo</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/IEGZQi3XfHk?si=PEZ6f7AZJ25z3RUH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Interaction</span>
            </v-col>
        </v-row>
        <v-row class="wrapper text-center">
            <v-col md='4' sm='12'>
                <v-img class='mt-5' src="@/assets/EchoMask/echo_mask_story_board1.jpg"></v-img>
                <span>After a long workday, your thoughts are still lingering on an unsatisfactory presentation to your manager. “Should have changed the title,” you keep thinking about it. Maybe it’s time to talk to the Echo Mask and let it go. </span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img class='mt-5' src="@/assets/EchoMask/echo_mask_story_board2.jpg"></v-img>
                <span>So, you walk to the Echo Mask on your desk. It is mumbling something about the pizza you were obsessed with the day before: “the pizza was good” “the pizza was good” “the pizza was good.” How strange it sounds now, you think. </span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img class='mt-5' src="@/assets/EchoMask/echo_mask_story_board3.jpg"></v-img>
                <span>You put your face into the inflated mask. The mask is morphing on your face as you breathe. You repeat the thought, “Should have changed the title,” until you can hear it played back to you. And you let go of the mask. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper text-center">
            <v-col md='4' sm='12'>
                <v-img class='mt-5' src="@/assets/EchoMask/echo_mask_story_board4.jpg"></v-img>
                <span>As air fills back in, your voice fades into the other phrases, including “the pizza was good.” Sometimes you hear “the title was good”, or “should have changed the pizza”, or a sea of meaningless sounds as if the machine is voiding the thoughts and feelings together. </span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img class='mt-5' src="@/assets/EchoMask/echo_mask_story_board_end.jpg"></v-img>
                <span>Your rumination stays in the box. </span>
            </v-col>
            <v-col md='4' sm='12'>
                <v-img class='mt-5' src="@/assets/EchoMask/echo_mask_story_board5.jpg"></v-img>
                <span>And you leave and move on with your life. </span>
            </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>System Overview</span>
                <v-img class='mt-5' src="@/assets/EchoMask/ID_screenshot.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>There are four parts in the physical system of the Echo Mask:</span>
                <ol> 
                <li> inflatable mask with embedded electrodes (red) </li>
                <li> circuit components that record the shape of the mask (blue) </li>
                <li> a microphone to record phrases (green) </li>
                <li> a speaker playing back sound clips </li>
                </ol>
            </v-col>
            <v-col md='12' sm='12'>
                <span>The mask is made from partially inflated transparent air pouches with interior sticky patches that can be pressed or squeezed into different shapes.</span>
                <span>The Arduino uses the Multi-Touch Kit* components to register the shape of the mask.</span>
                <span>Mel-frequency cepstrum coefficient are extracted from recorded phrases.</span>
                <span>The machine learning model is trained to associate the phrases as audio clips with the specific shape it receives at the same time.</span>
                <span>The speaker plays back recorded sounds all at once while giving the highest volume to the sound associated with the current shape.</span>
            </v-col>
        </v-row>
        
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Reflections</span>
                <v-img class='mt-5' src="@/assets/EchoMask/interaction.png"></v-img>
            </v-col>
        </v-row>
         <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>Through an autobiographical design process, I identify the following three design considerations for exploring rumination as a design space.</span>
                <ul> 
                    <li>Placing the mask on the face and feeling the vibration of my own voice demanded the most attention from me and therefore enhanced bodily awareness. </li>
                    <li>Hearing my own voice made me self-conscious and embarrassed while the words turned into a subconscious train of sounds.</li>
                    <li>The machine’s agency was evident. I found it very difficult to say some of the more uncomfortable or hurtful words I sometimes ruminated about, because it inherited my voice and because it was not under my full control when it was consuming and replaying the my thoughts.</li>
                </ul>
            </v-col>
         </v-row>

        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Publication</span>
                <span>Yumeng Zhuang and Daragh Byrne. 2023. Echo Mask: A Discursive Approach to Tangible Rumination on the Meaning of Thoughts. In <i>Companion Publication of the 2023 ACM Designing Interactive Systems Conference (DIS ‘23 Companion)</i>. Association for Computing Machinery, New York, NY, USA, 288–291. <a target="_blank" href="https://doi.org/10.1145/3563703.3596660">https://doi.org/10.1145/3563703.3596660</a></span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-subtitle'>Multi-Touch Kit Reference</span>
                <span>* Narjes Pourjafarian, Anusha Withana, Joseph A. Paradiso, and Jürgen Steimle. 2019. Multi-Touch Kit: A Do-It-Yourself Technique for Capacitive Multi-Touch Sensing Using a Commodity Microcontroller. In <i>Proceedings of the 32nd Annual ACM Symposium on User Interface Software and Technology (UIST ‘19)</i>. Association for Computing Machinery, New York, NY, USA, 1071–1083. <a target="_blank" href="https://doi.org/10.1145/3332165.3347895">https://doi.org/10.1145/3332165.3347895</a></span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"EchoMask",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.interactionEntries){
                console.log("find theme color", entry)
                if(entry.title=="Echo Mask"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>

<style scoped>
.simulation-img{
    max-width: 600px;
    margin: auto;
}
.chart-img{
    max-width: 500px;
    margin: auto;
}
</style>