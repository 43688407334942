<template>
  <div class="home">
    <v-row>
      <v-col id='hi-wrapper'>
        <AnimatedPolygon :sides="20" :update-interval="500" 
        :width="170" :min-radius="0.9" :fill-color="themeColor"
        :style="{position:'absolute', zIndex: 1, opacity: 0.5}"/>
        <h1 id="hi-message" class="hi-block">Hi! I'm Yumeng Zhuang.</h1>
        <h3 id="hi-statement" class="hi-block">I create things that don't fit into a category.</h3>
        <v-row id="personal-links" class="hi-block d-flex flex-row-reverse">
          <v-col md='4' sm='6'><v-btn text target="_blank" href='../cv_aejrn091221.pdf'>My CV</v-btn></v-col>
          <v-col md='4' sm='6'><v-btn text target="_blank" href="https://www.linkedin.com/in/%E9%9B%A8%E8%90%8C-%E5%BA%84-8a8482173/?locale=en_US">My LinkedIn Profile</v-btn></v-col>
        </v-row>
      </v-col> 
    </v-row>
    <v-row class="wrapper" style="margin-top: 0px">
      <v-col sm='12'>
        <span>Welcome to my website! I identify as a coder, an artist, a designer, and, besides all labels, a maker who makes things that either wedge into an existing concept or bridge gaps between different fields. This website showcases my sample works and I hope you will enjoy them.</span>
      </v-col>
    </v-row>
    <ProjectPage/>
  </div>
</template>

<script>
// @ is an alias to /src
import AnimatedPolygon from '@/components/AnimatedPolygon.vue';
import { mapState } from 'vuex'
import colors from 'vuetify/lib/util/colors';
import ProjectPage from "@/views/ProjectPage.vue"

export default {
  name: 'Home',
  components: {
    AnimatedPolygon,
    ProjectPage
  },
  data: () => ({
    themeColor: colors.orange.darken2
  })
}
</script>
<style lang='scss' scoped>
@import '~vuetify/src/styles/styles.sass';

#hi-message{
  font-weight: 300;
  padding-top: 50px;
}
#personal-links{
  max-height: 70px;
}

#hi-wrapper{
  padding-left:50px;
  position: relative;
  @media #{map-get($display-breakpoints, 'md-and-up')}{
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.hi-block{
  z-index: 2;
  position: relative;
  @media #{map-get($display-breakpoints, 'md-and-up')}{ 
      margin-left: 100px;
      display: flex;
  }
}
</style>
