<template>
    <div class='project-page'>
        <ProjectHero
        :sides="15"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                Mallard
            </template>
            <template v-slot:subtitle>
                Portrait of a Mallard Duck
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class='wrapper'>
            <v-col>
                <v-img src="@/assets/art/Mallard.jpg" class='painting-img' contain></v-img>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Statement</span>
                <span class="project-statement-text">Mallard was a duck I found outside my roommate’s house in Minnesota. She was brooding her eggs in a low, dry bush. I was surprised that she would not move even when we were close to her or when cars drove by, showing such tranquility in what must be for her chaotic human life. In this drawing, I used seemingly arbitrary crossing lines to represent the chaotic and dangerous environment the mallard duck sat in, while the resting pose of the duck conveyed a sense of peace, which may be a result of suppressed fear. As it turned out, a few weeks after I drew this portrait, the mother duck was eaten by a fox and we only managed to hatch one duckling from the eggs she left behind.  </span>
            </v-col>
        </v-row>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Pen on paper, 11x14 inches</span>
                <span>April 27, 2020</span>
                <span>This drawing was awarded Honorable Mention in Art Room Gallery “Animals” Online Art Exhibition in July 2020</span>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"Mallard",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.artEntries){
                console.log("find theme color", entry)
                if(entry.title=="Mallard"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>