<template>
    <div class='project-page'>
        <ProjectHero :src="'CPT/cpt_new-01.png'"
        :sides="7"  :min-radius="0.7" :theme-color="themeColor">
            <template v-slot:title>
                CPT Diagnostics
            </template>
            <template v-slot:subtitle>
                Gamma-Ray Spectrum Reconstruction Using EM Algorithm and Machine Learning
            </template>
        </ProjectHero>
        <v-divider></v-divider>
        <v-row class="project-statement-wrapper wrapper">
            <v-col>
                <span class="project-statement-title">Project Statement</span>
                <span class="project-statement-text">The Compton spectrometer under development at UCLA for the FACET-II experiments to be conducted at Stanford is a versatile tool to analyze gamma-ray spectra in a single shot, in which the energy and angular position of the incoming photons are recorded by observing the momenta and position of Compton scattered electrons. We present methods to reconstruct the primary spectrum from these data via machine learning and the EM Algorithm. A multi-layer fully connected neural network is used to perform the regression task of reconstructing both the double-differential spectrum and the photon energy spectrum incident with zero angular offset. We present the expected performance of these techniques, concentrating on the achievable energy resolution. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class='wrapper team-wrapper'>
            <v-col>
                <span>Team: Brian Naranjo, <b>Yumeng Zhuang</b>, James Rosenzweig</span>
                <span>My Role: Machine learning and adjusting the EM Algorithm</span>
                <span>Time: August 2019 – July 2021</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Problem</span>
                <span>The following picture shows the Compton Spectrometer designed by Brian Naranjo, on which this study was conducted.  </span>
                <v-img src="@/assets/CPT/cpt_new.png"></v-img>
                <span>Red trajectories are electron trajectories; blue trajectories are positron trajectories; the brown block is the coil for the magnet and the white block is the iron yoke; the semitransparent blue sheet is the scintillator and on top of the scintillator is an electron collimator. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>Sample scintillator output</span>
                <span>As electrons are scattered by light, they move in a curved path through the spectrometer, hitting the scintillators to create a pattern shown below.</span>
                <v-img class="center-small" src="@/assets/CPT/response4500.gif"></v-img>
            </v-col>
        </v-row>
        <v-row class='wrapper'>
            <v-col md='12' sm='12'>
                <span>As evident in the sample scintillator output, one shot of <b>monoenergetic gamma</b> does not produce one dot on the scintillator screen. Instead, it creates <b>a slightly curved pattern</b> with a tail at varying intensity. Therefore, we want to find a function that <b>invert</b> the output pattern to its input. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>Both the scintillator and the desired spectrum have finite resolution, so we divided the scintillator screen into squares and the energy-position spectrum into bins as well. As a result, the proposed problem becomes: Given <b>n∗(d)</b>, the  energy  deposited  in scintillator bin d,  and <b>p(b, d)</b>, the energy deposition in scintillator bin d of photons if they come from energy bin b, we are trying to find <b>λ(b)</b>, the total energy emitted by the gamma photons in each energy bin.</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Approaches for 1D Spectra</span>
                <span>This section focuses on reconstructing energy only, head-on gamma ray spectra.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-subtitle">EM Algorithm</span>
                <span>We have adapted the expectation-maximization (EM) algorithm by Vardi, Shepp, and Kaufman. The EM algorithm finds a set of parameters that maximizes the likelihood of a given outcome by iteration. It alternates between estimating the outcome using given parameters and maximizing the expected likelihood by computing a new set of parameters based on the previous estimation (Wikipedia, 2020)</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <v-img class="center-small" src="@/assets/CPT/em_code.png"></v-img>
                <span class='pt-3'>The piece of code to iterate the EM Algorithm,  where R=p(b,d), y=n∗(d), and x0=λold(b).</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>One option for p(b, d) is the energy deposition of monoenergetic gamma-rays.</span>
                <span>We also used a smoothed basis for p(b, d), i.e. replacing the original single peak in energy bin b with a Gaussian function centered at $b$ with finite width.</span>
                <span>We will discuss the results with the machine learning results in later sections.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-subtitle">Machine Learning</span>
                <span>A fully connected network, the model we used, is a series of matrix multiplication plus bias terms going through an activation function.</span>
                <v-img class="ml-eq" src="@/assets/CPT/network_eq.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>Data for learning the network was generated by superposing monoenergetic gamma ray spectra sampled from a uniform yet reasonably smooth distribution. Model parameters such as the number of layers, the size of each layer, the activation function of each layer, whether to add bias to the layer, learning rate of the model, etc. are searched via scripts. </span>
                <span>Our final selected model has three hidden layers of 128, 128, and 512 nodes, no bias, and all the activation functions are ReLU (Rectified Linear Unit) activation.</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-subtitle">Results</span>
                <span>Here we present the results of the three reconstruction algorithms: EM Algorithm with the original monoenergetic basis, the smoothed EM Algorithm, and machine learning. The spectrum used to test the the three reconstruction algorithms is the dataset 'E300 PWFA/Matched Trailing (s=0.06)/both', a simulated spectrum for the PWFA experiment at FACET-II.</span>
                <v-img class="center-medium" src="@/assets/CPT/1d_results.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span>The true PWFA spectrum is plotted in solid black. The red curve is the predicted spectrum from machine learning. The dashed green curve corresponds to the original EM Algorithm, and the blue dotted line is the result from the smoothed EM Algorithm. </span>
                <span>The dotted line agrees the best with the truth, and we conclude that smoothing improves the performance of EM algorithm on smooth spectra. This will incur error when the spectra we try to recover has distinct peaks. </span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-title">Preliminary Results for 2D Spectra</span>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-subtitle">Model</span>
                <span>I again used a fully connected regression model, with the following parameters:</span>
                <v-img class="center-medium" src="@/assets/CPT/2d_model.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-subtitle">Training</span>
                <span>2997 training samples with different input spectra are fed into the training cycle at batches of 64 samples per batch. </span>
                <v-img class="center-large" src="@/assets/CPT/train_process.png"></v-img>
            </v-col>
        </v-row>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class="section-subtitle">Results</span>
                
                <v-img class="center-large" src="@/assets/CPT/2d_results.png"></v-img>
                <span>Results for 2d reconstruction. For each rectangle, the image on the left is the true spectrum to be recovered. The image on the right is the reconstruction result. The overall shapes are quite similar in many cases. </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper">
            <v-col md='12' sm='12'>
                <span class='section-title'>Conclusion and Future Work</span>
                <span>Based on the discussion above, both EM Algorithm and machine learning are capable of reconstructing 1D energy spectra. Smooth training data as well as smooth bases greatly improve performance of the algorithms to reproduce smooth spectra. </span>
                <span>Similarly, efforts have been put into recovering the angular information with a fully connected neural network. Machine learning models are capable of reconstructing the overall shape of the spectra. </span>
                <span>Future work should consider using actual data from full simulations to use the EM Algorithm, since it is a lot faster and simpler to train. EM Algorithm’s application for the double differential spectra can also be explored. 
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="wrapper reference">
            <v-col md='12' sm='12'>
                <span class='section-title'>References</span>
                <ul>
                    <li>Vardi, Y., Shepp, L. A. (1985) ‘A Statistical Model for Positron Emission Tomography’, <i>Journal of the American Statistical Association</i>, Vol. 80, No. 389, pp. 8-20</li>
                    <li>Wikipedia. (2020) <i>Expectation–maximization algorithm</i> [Online]. Available at https://en.wikipedia.org/wiki/Expectation%E2%80%93maximization_algorithm</li>
                </ul>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//import colors from 'vuetify/lib/util/colors';
import ProjectHero from '@/components/ProjectHero.vue'
//import AnimatedPolygon from "@/components/AnimatedPolygon.vue"

export default {
    name:"CPTDiagnostics",
    components:{
        //AnimatedPolygon,
        ProjectHero
    },
    data: ()=>{
        return {
            //themeColor: '#504de5'
        }
    },
    computed:{
        //...mapState(['entries']), 
        themeColor(){
            for (let entry of this.$store.state.physicsEntries){
                console.log("find theme color", entry)
                if(entry.title=="CPT Diagnostics"){
                    return entry.titleBg
                }
            }
            return "#000000"
        }
    },
}
</script>

<style scoped>
.center-medium{
    max-width: 620px;
    margin: auto;
}
.center-small{
    max-width: 400px;
    margin: auto;
}
.ml-eq{
    max-width: 620px;
    margin: auto;
}
.model-2d{
    max-width: 610px;
    margin: auto;
}
</style>